import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserLocaleApi } from "services/utils/UtilsService"

import { IState } from "store"
import { onLocaleError, onSetLocale } from "store/slices/locale"
import { Log } from "utils/log"
import { retry } from "utils/promise"
import { SessionStorage, SessionStorageKeys } from "utils/sessions"
import { useAuth } from "./useAuth"
interface Locale {
  country: string
  region: string
}

export const useLocale = () => {
  const dispatch = useDispatch()

  const [userLocale, setUserLocale] = useState<string>()
  
  const { user, isLoadingUser } = useAuth()
  const { locale, state } = useSelector((state: IState) => state.locale)

  useEffect(() => {
    const storedLocale = SessionStorage<Locale>().get(SessionStorageKeys.current_location)

    if (storedLocale) {
      dispatch(onSetLocale(storedLocale))
      return
    }

    retry(getUserLocaleApi())
        .then(data => {
          const locale = {
            country: data.countryCode,
            region: data.regionName
          }
          SessionStorage().set(SessionStorageKeys.current_location, locale)
          dispatch(onSetLocale(locale))
        })
        .catch(err => {
          Log.error(err as Error)
          dispatch(onLocaleError())
        })
  }, [])

  useEffect(() => {
    if (user && !isLoadingUser && !userLocale && state !== "loading") {
      const userLocale = user.country ?? locale?.country

      setUserLocale(userLocale)
    }
  }, [user, isLoadingUser, locale])

  return {
    locale,
    state,
    userLocale
  }
}