import { useBreakpoints } from "hooks/useScreen"

import { SocialRegisterModalDesktop } from "./SocialRegisterModalDesktop"
import { SocialRegisterModalMobile } from "./SocialRegisterModalMobile"

type SocialRegisterModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
  animated: boolean
}

export function SocialRegisterModal({ goBackOrClose, onClose, animated }: SocialRegisterModalProps) {

  const { isDesktop } = useBreakpoints()

  if (isDesktop) {
    return <SocialRegisterModalDesktop 
      goBackOrClose={goBackOrClose}
      onClose={onClose}
    />
  }

  return <SocialRegisterModalMobile 
    goBackOrClose={goBackOrClose}
    onClose={onClose}
    animated={animated}
  />

}