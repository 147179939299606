import { ButtonHTMLAttributes } from 'react'
import styles from './styles.module.scss'
import { DotLoading } from 'components/shared/DotLoading'

type PrimaryButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean
  children: string
}

export const PrimaryButton = ({ 
  loading, children, type = "submit", onClick, className
}: PrimaryButtonProps) => {
  
  return (
    <button
      type={type}
      onClick={onClick}
      className={[
        styles.button,
        loading ? styles.loadingButton : "",
        className,
      ].join(" ")}
    >
      {loading ? <DotLoading style='secondary' /> : children}
    </button>
  )
}
