import styles from "./styles.module.scss"

type CheckboxProps = {
  label?: string
  checked: boolean
  onChecked: (checked: boolean) => void
}

function Checkbox({ label, checked, onChecked }: CheckboxProps) {

  return (
    <div className={styles.container}>
      <label>
        <input 
          type="checkbox"
          checked={checked}
          className={styles.checkbox}
          onChange={(e) => onChecked(e.target.checked)}
        />
        <i></i>
        <p>
          {label}
        </p>
      </label>
    </div>
  )
}

export { Checkbox }