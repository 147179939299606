import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import styles from "./styles.module.scss"

type UserProfileOptionsProps = {
  handleNavigateToProfile: () => void
  handleLogout: () => void
}

export function UserProfileOptions({ handleNavigateToProfile, handleLogout }: UserProfileOptionsProps) {

  const { t } = useDelirecTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.triangle} />
      <div className={styles.optionsContainer}>
        <div 
          onClick={handleNavigateToProfile}
          className={[styles.optionItem, styles.optionItemDivider].join(" ")}
        >
          <img src="/assets/header/header_user_option_profile.svg" />
          <p className={styles.optionItemText}>{t("header.my_profile")}</p>
        </div>
        <div className={styles.optionItem} onClick={handleLogout}>
          <img src="/assets/header/header_user_option_profile.svg" />
          <p className={styles.optionItemText}>{t("header.logout")}</p>
        </div>
      </div>
    </div>
  )
}