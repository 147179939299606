import { 
  FieldValues, Path, PathValue, SetValueConfig, UnpackNestedValue, useForm, UseFormProps
} from "react-hook-form"

export type UseFormSetValueTrigger = (
  name: string,
  value: any,
  options?: Partial<{
    shouldValidate: boolean;
    shouldDirty: boolean;
    shouldTouch: boolean;
  }> | undefined) => void

export function useDeliRecForm<TFieldValues extends FieldValues>(props?: UseFormProps<TFieldValues>) {
  const form = useForm<TFieldValues>(props)

  return {
    ...form,
    setValueTrigger: (
      name: Path<TFieldValues>,
      value: UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>,
      options?: SetValueConfig
    ) => {
      form.setValue(name, value, options)
      form.trigger(name)
    }
  }
}