import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useMemo, useState } from "react"
import { sendDownloadClickEvent } from "utils/events/downloadAppEvents"
import styles from "./styles.module.scss"
import { useBrowser } from "hooks/useBrowser"

export function NewAppDownloadModal() {

  const [animateClose, setAnimatedClose] = useState(false)
  const [showLater, setShowLater] = useState(false)
  const { t } = useDelirecTranslation()
  const { isChrome, isSafari } = useBrowser()

  const browserIcon = useMemo(() => {
    if (isChrome) {
      return "/assets/download_button/chrome_icon.svg"
    } else if (isSafari) {
      return "/assets/download_button/safari_icon.svg"
    } else {
      return "/assets/download_button/browser_icon.svg"
    }
  }, [isChrome, isSafari])

  function handleLogDownloadClick() {
    sendDownloadClickEvent({
      source: "default"
    })
  }

  function handleLogFloatingDownloadClick() {
    sendDownloadClickEvent({
      source: "floating"
    })
  }

  function handleLaterClick() {
    setAnimatedClose(true)
  }

  function handleOnAnimationEnd() {
    if (animateClose) {
      setShowLater(true)
    }
  }

  if (showLater) {
    return (
      <div className={styles.downloadContainer}>
        <div className={styles.miniDownloadContainer}>
          <a 
            onClick={handleLogFloatingDownloadClick}
            href="https://delirec.page.link/popup_download_1"
          >
            {t("newDownloadModal.primaryAction")}
          </a>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={[
          styles.popupBackground, animateClose && styles.popupBackgroundClose
        ].join(" ")}
      />
      <div className={[
          styles.popupContainer, animateClose && styles.popupContainerClose
        ].join(" ")}
        onAnimationEnd={handleOnAnimationEnd}
      >
        <p className={styles.popupTitle}>{t("newDownloadModal.title")}</p>
        <div className={[styles.popupLineItem, styles.popupPrimary].join(" ")}>
          <img src="/assets/download_button/delirec_icon.svg" />
          <p>{t("newDownloadModal.primaryText")}</p>
          <a href="https://delirec.page.link/popup_download_1" onClick={handleLogDownloadClick}>
            <button>
              {t("newDownloadModal.primaryAction")}
            </button>
          </a>
        </div>
        <div className={[styles.popupLineItem, styles.popupSecondary].join(" ")}>
          <img src={browserIcon} />
          <p>{t("newDownloadModal.secondaryText")}</p>
          <button onClick={handleLaterClick}>
            {t("newDownloadModal.secondaryAction")}
          </button>
        </div>
      </div>
    </>
  )
}
