import { useMemo } from "react"
import { useRouter } from "next/router"

import { useAuth } from "hooks/useAuth"

import { DeliRecPages, navigationRoutePath } from "utils/routes"

import { PageRoute } from "services/router/routerService"

import styles from "./styles.module.scss"

type BottomNavigationProps = {
  page?: DeliRecPages
  navigationRoutes: PageRoute[]
}

export function BottomNavigation({ page, navigationRoutes }: BottomNavigationProps) {

  const router = useRouter()
  const { handleActionOrOpenLogin } = useAuth()

  const selected = useMemo(() => {
    switch (page) {
      case "home":
        return 0
      case "search":
        return 1
      case "send-recipe":
        return 2
      case "my-menu":
        return 3
      case "profile":
        return 4
      default:
        return undefined
    }
  }, [page])

  const onClick = (index: number) => {
    if (index === selected) {
      return
    }
    switch (index) {
      case 0:
        router.push(navigationRoutePath(router, "home", navigationRoutes))
        break
      case 1:
        router.push(navigationRoutePath(router, "search", navigationRoutes))
        break
      case 2:
        handleActionOrOpenLogin(user => {
          if (user) {
            router.push(navigationRoutePath(router, "send-recipe", navigationRoutes))
          }
        })
        break;
      case 3:
        router.push(navigationRoutePath(router, "my-menu", navigationRoutes))
        break
      case 4:
        handleActionOrOpenLogin(user => {
          if (user) {
            router.push(`/${user.username}`)
          }
        })
        break
    }
  }

  const tabs = useMemo(() => [
    { name: "home" },
    { name: "search" },
    { name: "send-recipe" },
    { name: "my-menu" },
    { name: "profile" },
  ], [])

  return (
    <ul className={styles.container}>
      {
        tabs.map((tab, index) => {
          const isSelected = index === selected
          return (
            <li
              key={tab.name}
              onClick={() => onClick(index)}
            >
              <img
                src={`/assets/navigation/${tab.name}${isSelected ? "-selected" : "-unselected"}.svg`}
                alt={tab.name}
              />
            </li>
          )
        })
      }
    </ul>
  )
}
