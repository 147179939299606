import { useEffect } from "react"
import { useRouter } from "next/router"

import { PageRoute } from "services/router/routerService"

import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useAuth } from "hooks/useAuth"

import { Link } from "components/shared/Link"

import { useNavigationRoutePath, supportUrl } from "utils/routes"

import styles from "./styles.module.scss"

type MobileHeaderMenuProps = {
  openDrawer: boolean
  handleCloseDrawer: () => void
  navigationRoutes: PageRoute[]
}

export function MobileHeaderMenu({ 
  openDrawer, handleCloseDrawer, navigationRoutes
}: MobileHeaderMenuProps) {

  const { t } = useDelirecTranslation()
  const router = useRouter()
  const { user, userFirstName, handleOpenLogin } = useAuth()

  const aboutPath = useNavigationRoutePath(router, "about", navigationRoutes)
  const categoriesPath = useNavigationRoutePath(router, "categories", navigationRoutes)
  const themesPath = useNavigationRoutePath(router, "theme", navigationRoutes)
  const contactPath = useNavigationRoutePath(router, "contact", navigationRoutes)
  const termsPath = useNavigationRoutePath(router, "terms", navigationRoutes)
  const privacyPath = useNavigationRoutePath(router, "privacy", navigationRoutes)

  function handleOpenLoginScreen() {
    handleCloseDrawer()
    if (user) {
      return router.push(`/@${user?.username}`)
    } 
    return handleOpenLogin()
  }

  useEffect(() => {
    if (openDrawer) {
      document.addEventListener('click', handleOnClick);
      return () => document.removeEventListener('click', handleOnClick);
    }
  }, [openDrawer]);

  const handleOnClick = (event: MouseEvent) => {
    if (event.target instanceof HTMLButtonElement
        || event.target instanceof HTMLAnchorElement) {
      handleCloseDrawer()
    }
  }

  return (
    <div
      className={`
        ${styles.mobileNavigation}
        ${openDrawer && styles.openNavigation}
      `}
    >
      <div className={styles.headerMenu}>
        <div className={styles.headerMenuTitle}>
          <img
            onClick={handleCloseDrawer}
            src="/assets/header/header_menu_opened.svg"
            alt="Fechar menu"
            className={styles.logo}
          />

          {user ? 
            (<p className={styles.headerMenuTitleText}>{t("header.hy")} {userFirstName}</p>) : 
            (<p className={styles.headerMenuTitleText}>{t("header.unlogged.title")}</p>)
          }

        </div>
        {
          user ? (
            <Link href={`/${user.username}`}>
              <button className={styles.headerMenuButton}>
                {t("header.my_account")}
              </button>
            </Link>
          ) : (
            <button className={styles.headerMenuButton} onClick={handleOpenLoginScreen} >
              {t("header.loginOrRegister")}
            </button>
          )
        }
      </div>
      <div className={styles.headerMenuContent}>
        <div className={styles.mobileHeaderLinks}>
          <ul className={styles.mobileHeaderLinkItem}>
            <li><Link href={`${aboutPath}`}>{t("header.about")}</Link></li>
            <li><Link href={`${categoriesPath}`}>{t("header.categories")}</Link></li>
            <li><Link href={`${themesPath}`}>{t("header.themes")}</Link></li>
            <li><Link href="https://delirec.page.link/header-download">{t("header.app_delirec")}</Link></li>
            <li><Link href={`${contactPath}`}>{t("header.contact")}</Link></li>
            <li>
              <Link href={supportUrl}>
                {t("help_central")}
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.mobileHeaderFooter}>
          <p className={styles.mobileHeaderFollowUs}>{t("header.followUs")}</p>
          <div className={styles.mobileSocialNetworks}>
            <Link href="https://www.facebook.com/delirec.br">
              <img src="/assets/socialIcons/facebook_icon.svg" alt="Facebook" />
            </Link>
            <Link href="https://www.instagram.com/delirec.br">
              <img src="/assets/socialIcons/instagram_icon.svg" alt="Instagram" />
            </Link>
          </div>
          <ul className={styles.mobileHeaderLinkItem}>
            <li><Link href={termsPath}>{t("terms")}</Link></li>
            <li><Link href={privacyPath}>{t("privacy")}</Link></li>
          </ul>
        </div>
      </div>
    </div>
  )
}