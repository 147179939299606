import { ApiClient } from "services/apiClient";
import { DEFAULT_COUNTRY } from "utils/constants";
import { getDeviceType } from "utils/utils";

type EventResponse = {
  error: boolean
  data?: string
}

export async function sendZaplyEvent(event: string, params: Object) {
  const { data } = await ApiClient.post<EventResponse>("/v1/groceries/events", {
    event,
    country: DEFAULT_COUNTRY,
    params: {
      ...params,
      device: getDeviceType()
    }
  })
  return data
}
