class InvalidEmailException extends Error {
  constructor(message?: string) {
      super(message)
      this.name = "InvalidEmailException"
  }
}

class WeakPasswordException extends Error {
constructor(message?: string) {
    super(message)
    this.name = "WeakPasswordException"
}
}

class EmailAlreadyInUseException extends Error {
constructor(message?: string) {
    super(message)
    this.name = "EmailAlreadyInUseException"
}
}

class InvalidCredentialsException extends Error {
  constructor(message?: string) {
      super(message)
      this.name = "InvalidCredentialsException"
  }
}

class TooManyRequestsException extends Error {
  constructor(message?: string) {
      super(message)
      this.name = "TooManyRequestsException"
  }
}

class UserNotFoundException extends Error {
  constructor(message?: string) {
      super(message)
      this.name = "UserNotFoundException"
  }
}

export { 
  InvalidEmailException,
  InvalidCredentialsException,
  TooManyRequestsException,
  UserNotFoundException,
  WeakPasswordException,
  EmailAlreadyInUseException
}