import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthScreens } from "components/AuthModal";

export interface IAuthUser {
  uid: string;
  name?: string
  photo?: string
  username?: string
  bio?: string
  superChef?: boolean
  token: string
  country?: string
}

type PendingAction = (user?: IAuthUser) => void

//Actions
type OpenPopUpPayload = {
  screen: AuthScreens
  pendingAction?: PendingAction
}

//State
export interface IAuthState {
  user?: IAuthUser
  isLoadingUser: boolean
  authScreen?: AuthScreens
  pendingAction?: PendingAction
}

const INITIAL_STATE: IAuthState = {
  user: undefined,
  isLoadingUser: true,
  authScreen: undefined,
  pendingAction: undefined
};

//Slice
const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    onOpenAuthPopUp: (state: IAuthState, action: PayloadAction<OpenPopUpPayload>) => {
      state.authScreen = action.payload.screen
      state.pendingAction = action.payload.pendingAction
    },
    onCloseAuthPopUp: (state: IAuthState) => {
      state.authScreen = undefined
    },
    clearPendingAction: (state: IAuthState) => {
      state.pendingAction = undefined
    },
    onChangeIsLoadingUser: (state: IAuthState, action: PayloadAction<boolean>) => {
      state.isLoadingUser = action.payload
    },
    onLogin: (state: IAuthState, action: PayloadAction<IAuthUser>) => {
      state.user = action.payload
    },
    onUpdateUser: (state: IAuthState, action: PayloadAction<IAuthUser>) => {
      state.user = action.payload
    },
    onLogout: (state: IAuthState) => {
      state.user = undefined
      state.isLoadingUser = false
    }
  }
})

export const { 
  onLogin, onLogout, onOpenAuthPopUp, onCloseAuthPopUp, clearPendingAction, onChangeIsLoadingUser, onUpdateUser
} = authSlice.actions
export default authSlice.reducer