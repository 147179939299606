import { useEffect, useMemo } from "react"
import { NextComponentType, NextPageContext } from "next/types"
import { useRouter } from "next/router"

import { Header } from "Partials/Header"

import { RequestCookieConsent } from "components/shared/RequestCookieConsent"

import { useLocale } from "hooks/useLocale"

import { DeliRecPages } from "utils/routes"

import { FixedFooter } from "./FixedFooter"

import { PageRoute } from "services/router/routerService"

import { useAuth } from "hooks/useAuth"
import { getLoggedUserTokenV2 } from "services/sso/firebaseAuthUtilsService"
import { getUserByToken } from "services/sso/ssoService"
import { Log } from "utils/log"

type DeliRecPageProps = {
  page?: DeliRecPages
  data?: any
}

type AppComponentProps = {
  Component: NextComponentType<NextPageContext, any, {}>
  pageProps: any
}

export function AppComponent({ Component, pageProps }: AppComponentProps) {
  useLocale()
  const router = useRouter()
  const auth = useAuth()

  const listHideHeader: DeliRecPages[] = [
    "how-it-works",
    "theme-item",
    "category",
    "box-menu",
    "search",
  ];

  const listAppDownloadModal: DeliRecPages[] = [
    "box-menu",
  ];

  const navigationRoutes = useMemo(() => {
    if (pageProps?.data) {
      return pageProps.data?.navigationRoutes ?? ([] as PageRoute[])
    }
    return []
  }, [pageProps?.data])

  const pathData = useMemo(() => {
    if (pageProps?.data) {
      return pageProps.data?.pathData as PageRoute
    }
    return {} as PageRoute
  }, [pageProps?.data])

  const delirecPageProps = useMemo(() => {
    if (router.asPath === "/") {
      return {
        page: "home" as DeliRecPages,
      }
    }
    if (pageProps?.page) {
      return pageProps as DeliRecPageProps
    }
    return undefined
  }, [pageProps?.page])

  const showHeader = useMemo(() => {
    if (delirecPageProps?.page) {
      return !listHideHeader.includes(delirecPageProps.page)
    }
    return true
  }, [delirecPageProps?.page])

  const showAppDownloadModal = useMemo(() => {
    if (delirecPageProps?.page) {
      return !listAppDownloadModal.includes(delirecPageProps.page)
    }
    return true
  }, [delirecPageProps?.page])

  //Get user on page first load methods
  async function handleGetLoggedUser() {
    try {
      //Get logged user in firebase
      const token = await getLoggedUserTokenV2()

      if (token) {
        //Get user data from API
        const user = await getUserByToken(token)
        if (user) {
          auth.handleOnLogin({
            ...user,
            token: token,
          })
        } else {
          await auth.handleOnLogout()
        }
      }
    } catch(err) {
      Log.error(err as Error)
      await auth.handleOnLogout()
    }
    auth.handleChangeIsLoadingUser(false)
  }

  useEffect(() => {
    if (!auth.user && auth.isLoadingUser) {
      handleGetLoggedUser()
    }
  }, [auth.user, auth.isLoadingUser])

  return (
    <>
      {showHeader && (
        <Header pathData={pathData} navigationRoutes={navigationRoutes} />
      )}
      <Component {...pageProps} />
      <RequestCookieConsent />
      <FixedFooter
        page={delirecPageProps?.page}
        navigationRoutes={navigationRoutes}
        showAppDownloadModal={showAppDownloadModal}
      />
    </>
  );
}