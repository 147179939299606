import { useBreakpoints } from "hooks/useScreen"

import { ForgotPasswordModalDesktop } from "./ForgotPasswordModalDesktop"
import { ForgotPasswordModalMobile } from "./ForgotPasswordModalMobile"

type ForgotPasswordModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
  animated: boolean
}

export function ForgotPasswordModal({
  goBackOrClose, onClose, animated
}: ForgotPasswordModalProps) {

  const { isDesktop } = useBreakpoints()

  if (isDesktop) {
    return <ForgotPasswordModalDesktop 
      goBackOrClose={goBackOrClose}
      onClose={onClose}
    />
  }

  return <ForgotPasswordModalMobile 
    goBackOrClose={goBackOrClose}
    onClose={onClose}
    animated={animated}
  />

}