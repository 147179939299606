import useSWR from "swr"
import { useState } from "react"
import * as Sentry from "@sentry/nextjs"

import { appleLogin, emailLogin, facebookLogin, googleLogin } from "services/sso/firebaseSignInService";
import { getSocialMethods, getUserByToken } from "services/sso/ssoService"
import { getLoggedUserToken } from "services/sso/firebaseAuthUtilsService";

import { useDeliRecForm } from "hooks/useDeliRecForm"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useAuth } from "hooks/useAuth"

import { InvalidCredentialsException, InvalidEmailException, TooManyRequestsException } from "../../../services/sso/errors"

import { sendOnLoginEvent } from "utils/events/authEvents";
import { emailValidation } from "utils/validationPatterns";

import { AuthScreens } from ".."

type LoginViewModelProps = {
  pushNewScreen: (screen: AuthScreens) => void
  onClose: (dismiss: boolean) => void
}

type LoginForm = {
  email: string
  password: string
}

type SocialMethods = "google" | "facebook" | "apple"

export const useLoginViewModel = ({ pushNewScreen, onClose }: LoginViewModelProps) => {

  const { t } = useDelirecTranslation()
  const { register, handleSubmit, formState: { errors }, setError } = useDeliRecForm<LoginForm>()
  const { handleOnLogin, handleOnLogout } = useAuth()

  const { data } = useSWR("v1/sso/methods", getSocialMethods, { 
    revalidateOnFocus: false
  })
  const [areSubmittingForm, setAreSubmittingForm] = useState(false)

  const emailInput = register("email", {
    required: { value: true, message: t("oauth.errors.emptyEmail") },
    pattern: { value: emailValidation, message: t("oauth.errors.invalidCredentials") }
  })
  const passwordInput = register("password", {
    required: { value: true, message: t("oauth.errors.emptyPasswordSignIn") },
    minLength: { value: 6, message: t("oauth.errors.invalidCredentials") }
  })

  async function onSubmit(data: LoginForm) {
    const { email, password } = data
    if (areSubmittingForm) {
      return;
    }
    setAreSubmittingForm(true)

    try {
      await emailLogin(email, password)

      handleLogin("email")
    } catch(error: any) {
      const _error = error as Error

      Sentry.captureException(_error)

      handleLoginError(_error)
    }
    setAreSubmittingForm(false)
  }

  function navigateToForgotPassword() {
    pushNewScreen("forgotPassword")
  }

  const handleGoogleLogin   = () => handleSocialLogin("google")
  const handleFacebookLogin = async () => handleSocialLogin("facebook")
  const handleAppleLogin    = () => handleSocialLogin("apple")

  async function handleSocialLogin(method: SocialMethods) {
    try {
      const functionMethods = {
        google: () => {
          return googleLogin()
        },
        facebook: () => {
          return facebookLogin()
        },
        apple: () => {
          return appleLogin()
        }
      }

      const user = await functionMethods[method]()
      if (user) {
        handleLogin(method)
      }
    } catch (err: any) {
      Sentry.captureException(err)
      handleLoginError(err)
    }
  }

  async function handleLogin(origin: string) {
    try {
      const token = await getLoggedUserToken()
      const user = await getUserByToken(token)
      
      if (user) {
        const authUser = {
          ...user,
          token: token
        }

        sendOnLoginEvent({
          user_uid: user.uid,
          user_username: user.username ?? "",
          method: origin 
        })

        handleOnLogin(authUser)
        onClose(false)
      } else {
        pushNewScreen("complete")
      }
    } catch(err) {
      Sentry.captureException(err)
      await handleOnLogout()
      setError("password", { type: "custom", message: t("generalError") })
    }
  }

  function handleLoginError(error: Error) {
    if (error instanceof InvalidCredentialsException) {
      setError("password", { type: "custom", message: t("oauth.errors.invalidCredentials") })
    } else if (error instanceof InvalidEmailException) {
      setError("email", { type: "custom", message: t("oauth.errors.invalidEmail") })
    } else if (error instanceof TooManyRequestsException) {
      setError("password", { type: "custom", message: t("oauth.errors.tooManyRequests") })
    } else {
      setError("password", { type: "custom", message: t("generalError") })
    }
  }

  function navigateToRegister() {
    pushNewScreen("register")
  }

  return {
    handleSubmit,
    errors,
    setError,
    onSubmit,
    navigateToForgotPassword,
    socialMethods: data,
    handleGoogleLogin,
    handleFacebookLogin,
    handleAppleLogin,
    areSubmittingForm,
    t,
    navigateToRegister,
    emailInput,
    passwordInput
  }
}