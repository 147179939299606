import { TextField } from "components/AuthModal/components/TextField"

import { emailValidation } from "utils/validationPatterns"
import { validateConfirmation } from "utils/validation"

import { useRegisterViewModel } from "../viewModel"

import { ModalCancelButton } from "../../ModalCancelButton"
import { AuthFormTerms } from "../../components/AuthFormTerms"
import { AuthFormButton } from "../../components/AuthFormButton"

import styles from "./styles.module.scss"

type RegisterModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
}

export function RegisterModalDesktop({ goBackOrClose, onClose }: RegisterModalProps) {

  const { 
    errors, handleSubmit,  handleRegister, handleReturn, loading, setValueTrigger,  t,
    watchAcceptTerms, watchEmail, watchPassword, nameInput, usernameInput, emailInput,
    emailConfirmInput, passwordInput, passwordConfirmInput
  } = useRegisterViewModel({ goBackOrClose, onClose })

  function handleClose() {
    onClose(true)
  }

  return (
    <div>
      <div className={styles.background} onClick={handleClose} />
      <div className={styles.modal}>
        <p className={styles.modalTitle}>
          {t("oauth.register.title")}
        </p>
        <form onSubmit={handleSubmit(handleRegister)} className={styles.modalForm}>
          <TextField 
            id="name"
            type="text"
            placeholder={t('oauth.fields.name')}
            error={errors.name?.message}
            register={nameInput}
          />
          <TextField 
            id="username"
            type="text"
            placeholder={t('oauth.fields.username')}
            textCase="lower"
            suffixIcon="/assets/oauth/icon_username.svg"
            className={styles.formTopMargin}
            error={errors.username?.message}
            register={usernameInput}
          />
          <TextField 
            id="email"
            type="text"
            placeholder={t('oauth.fields.email')}
            suffixIcon="/assets/oauth/icon_email.svg"
            className={styles.formTopMargin}
            error={errors.email?.message}
            register={emailInput}
          />
          {watchEmail?.length > 0 && (
            <TextField 
              id="email-confirm"
              type="text"
              placeholder={t('oauth.fields.emailConfirm')}
              suffixIcon="/assets/oauth/icon_email.svg"
              className={styles.formTopMargin}
              error={errors.confirmEmail?.type === "validate" ? t('oauth.errors.emailDontMatch') : undefined}
              register={emailConfirmInput}
            />
          )}
          <TextField 
            id="password"
            type="password"
            placeholder={t('oauth.fields.password')}
            suffixIcon="/assets/oauth/icon_password.svg"
            className={styles.formTopMargin}
            error={errors.password?.message}
            register={passwordInput}
          />
          {watchPassword?.length > 0 && (
            <TextField  
              id="password-confirm"
              type="password"
              placeholder={t('oauth.fields.passwordConfirm')}
              suffixIcon="/assets/oauth/icon_password.svg"
              className={styles.formTopMargin}
              error={errors.confirmPassword?.type === "validate" ? t('oauth.errors.passwordDontMatch') : undefined}
              register={passwordConfirmInput}
            />
          )}
          <AuthFormButton
            text={t('oauth.actions.signUp')}
            loading={loading}
            className={styles.formTopMargin}
          />
          <AuthFormTerms
            acceptTerms={watchAcceptTerms}
            setAcceptTerms={value => setValueTrigger('acceptTerms', value)}
            error={errors.acceptTerms?.message}
            className={styles.terms}
          />
          <ModalCancelButton onCancel={handleReturn} />
        </form>
      </div>
    </div>
  )
}