import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalStorage, LocalStorageKeys } from "utils/storage";

export interface ILocale {
  country: string
  region: string
}

//State
export interface ILocaleState {
  locale: ILocale | null
  state: "loading" | "loaded" | "error"
}

const INITIAL_STATE: ILocaleState = {
  locale: LocalStorage<ILocale>().get(LocalStorageKeys.current_location),
  state: "loading"
};

//Slice
const authSlice = createSlice({
  name: "locale",
  initialState: INITIAL_STATE,
  reducers: {
    onSetLocale: (state: ILocaleState, action: PayloadAction<ILocale>) => {
      state.locale = action.payload
      state.state = "loaded"
    },
    onLocaleError: (state: ILocaleState) => {
      if (state.state === "loading" && state.locale !== null) {
        state.state = "loaded"
      } else {
        state.state = "error"
      }
    }
  }
})

export const { 
  onSetLocale, onLocaleError
} = authSlice.actions
export default authSlice.reducer