import { useEffect, useState } from "react"
import * as Sentry from "@sentry/nextjs"

import { checkUsernameExists, completeSignUp } from "services/sso/ssoService"
import { getLoggedUserToken, getSignInMethodsForEmail } from "services/sso/firebaseAuthUtilsService"
import { passwordSignUp } from "services/sso/firebaseSignUpService"

import { useDeliRecForm } from "hooks/useDeliRecForm"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useAuth } from "hooks/useAuth"

import { EmailAlreadyInUseException, InvalidEmailException, TooManyRequestsException } from "services/sso/errors"
import { sendOnRegisterEvent } from "utils/events/authEvents"
import { useLocale } from "hooks/useLocale"
import { emailValidation } from "utils/validationPatterns"
import { validateConfirmation } from "utils/validation"

type RegisterViewModelProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
}

type RegisterForm = {
  name: string
  username: string
  email: string
  confirmEmail: string
  password: string
  confirmPassword: string
  acceptTerms: boolean
}

export const useRegisterViewModel = ({ onClose, goBackOrClose }: RegisterViewModelProps) => {

  //Helper hooks
  const { handleOnLogin, handleOnLogout } = useAuth()
  const { t } = useDelirecTranslation()
  const { register, handleSubmit, formState: { errors }, setError, setValueTrigger, watch } = useDeliRecForm<RegisterForm>()
  const { locale } = useLocale()
  
  //Local state
  const [loading, setLoading] = useState(false)

  //Helper variables
  const watchEmail: string | undefined = watch("email") ?? ""
  const watchPassword: string | undefined = watch("password") ?? ""
  const watchAcceptTerms: boolean | undefined = watch("acceptTerms") ?? ""

  //Forms register
  const nameInput = register("name", {
    required: { value: true, message: t('oauth.errors.emptyName') },
  })
  const usernameInput = register("username", {
    required: { value: true, message: t('oauth.errors.emptyUsername') },
    maxLength: { value: 30, message: t('oauth.errors.usernameTooLong') },
    pattern: { value: /^[a-zA-Z0-9_]+$/, message: t('oauth.errors.usernameInvalid') },
  })
  const emailInput = register("email", {
    required: { value: true, message: t('oauth.errors.emptyEmail') },
    pattern: { value: emailValidation, message: t('oauth.errors.invalidEmail') },
  })
  const emailConfirmInput = register("confirmEmail", {
    validate: confirmEmail => validateConfirmation(confirmEmail, watchEmail),
  })
  const passwordInput = register("password", {
    required: { value: true, message: t('oauth.errors.emptyPasswordSignUp') },
    minLength: { value: 8, message: t('oauth.errors.shortPasswordSignUp') },
  })
  const passwordConfirmInput = register("confirmPassword", {
    validate: confirmPassword => validateConfirmation(confirmPassword, watchPassword),
  })

  useEffect(() => {
    register("acceptTerms", {
      required: { value: true, message: t("oauth.errors.didNotAcceptedTheTerms") }
    })
  }, [register])

  async function checkEmailIsAvailable(email: string) {
    const response = await getSignInMethodsForEmail(email)
    return response.length == 0
  }

  async function handleRegister(fields: RegisterForm) {
    if (loading) {
      return;
    }

    setLoading(true)
    const [isEmailAvailable, isUsernameUsed] = await Promise.all([
      checkEmailIsAvailable(fields.email),
      checkUsernameExists(fields.username)
    ])
    
    if (isEmailAvailable && !isUsernameUsed) {
      try {
        const credentials = await passwordSignUp(fields.email, fields.password)
        //SignUp, save username and save the userdata
        const token = await credentials.getIdToken()
        const { error, message, data: userData } = await completeSignUp(
          token,
          fields.name,
          fields.username,
          ["password"],
          locale?.country
        )

        if (error) {
          onRegisterError(new Error(message))
        } else {
          const token = await getLoggedUserToken()

          sendOnRegisterEvent({
            user_uid: userData!.uid,
            user_username: userData!.username ?? "",
            method: "email"
          })

          handleOnLogin({
            ...userData!,
            token: token
          })
          onClose(false)
        }
      } catch(error: any) { //TS está com um problema que não permite tipar o erro do Catch
        const _error = error as Error
        Sentry.captureException(error)
        onRegisterError(_error)
      }
    } else {
      if (isUsernameUsed) {
        setError("username", { type: "custom", message: t("oauth.errors.usernameNotAvailable") })
      }
      if (!isEmailAvailable) {
        setError("email", { type: "custom", message: t("oauth.errors.emailAlredUsed") })
      }
    }
    setLoading(false)
  }

  async function onRegisterError(error: Error) {
    await handleOnLogout()
    if (error instanceof EmailAlreadyInUseException) {
      setError("email", { type: "custom", message: t("oauth.errors.emailAlredUsed") })
    } else if (error instanceof InvalidEmailException) {
      setError("email", { type: "custom", message: t("oauth.errors.invalidEmail") })
    } else if (error instanceof TooManyRequestsException) {
      setError("name", { type: "custom", message: t("oauth.errors.tooManyRequests") })
    } else {
      setError("name", { type: "custom", message: t("generalError") })
    }
  }

  function handleReturn() {
    goBackOrClose()
  }

  useEffect(() => {
    if (watchEmail?.length === 0) {
      setValueTrigger("confirmEmail", "")
    }
  }, [watchEmail])

  useEffect(() => {
    if (watchPassword?.length === 0) {
      setValueTrigger("confirmPassword", "")
    }
  }, [watchPassword])

  return {
    loading,
    t,
    register,
    errors,
    setValueTrigger,
    handleSubmit,
    handleRegister,
    watchEmail,
    watchPassword,
    watchAcceptTerms,
    handleReturn,
    nameInput,
    usernameInput,
    emailInput,
    emailConfirmInput,
    passwordInput,
    passwordConfirmInput
  }
}