import { auth } from './firebaseClient'
import * as Sentry from "@sentry/nextjs"
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
  signOut
} from 'firebase/auth'
import {
  InvalidCredentialsException,
  InvalidEmailException,
  TooManyRequestsException
} from 'services/sso/errors'

async function emailLogin(email: string, password: string) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)

    return userCredential.user
  } catch(err: any) {
    Sentry.captureException(err)
    switch (err.code) {
      case "auth/invalid-email":
        throw new InvalidEmailException()
      case "auth/user-not-found":
      case "auth/wrong-password":
        throw new InvalidCredentialsException()
      case "auth/too-many-requests":
        throw new TooManyRequestsException()
      default:
        throw new Error(err.message)
    }
  }
}

async function socialSignIn(provider: any) {
  try {
    const result = await signInWithPopup(auth, provider)
    return result.user
  } catch (err: any) {
    Sentry.captureException(err)
    switch (err.code) {
      case 'auth/popup-closed-by-user':
        return null;
      default:
        throw new Error(err.mesage)
    }
  }
}

async function googleLogin() {    
  return await socialSignIn(new GoogleAuthProvider())
}

async function facebookLogin() {
  return await socialSignIn(new FacebookAuthProvider())
}

async function appleLogin() {
  return await socialSignIn(new OAuthProvider('apple.com'))
}

async function logout() {
  return signOut(auth)
}

export { emailLogin, googleLogin, facebookLogin, appleLogin, logout }