import { useRouter } from "next/router"
import NextLink from "next/link"
import { HTMLAttributeAnchorTarget, MouseEvent } from "react"

type LinkProps = {
  href: string
  children: React.ReactNode | string
  className?: string
  dataTestId?: string
  onClick?: (event: MouseEvent<HTMLAnchorElement> ) => void
  target?: HTMLAttributeAnchorTarget 
}

type RouteQueries = {
  [key: string]: string | undefined
  utm_campaign?: string
  utm_source?: string
  utm_medium?: string
}

export const Link = ({ href, children, className, onClick, target, dataTestId }: LinkProps) => {
  const router = useRouter()
  const queries = router.query as RouteQueries

  const _utms = Object.keys(queries).reduce((acc, key) => {
    if (key.startsWith("utm_")) {
      const divider = acc.length === 0 ? "?" : "&"
      acc += `${divider}${key}=${queries[key]}`
    }
    return acc
  }, "")

  const _urlWithUtms = `${href}${_utms}`

  if (href.includes("http")) {
    return (
      <a 
        href={href}
        className={className}
        data-testid={dataTestId}
        target={target}
      >
        {children}
      </a>
    )
  }

  if (className) {
    return (
      <NextLink href={_urlWithUtms} >
        <a 
          className={className}
          onClick={onClick}
          data-testid={dataTestId}
          target={target}
        >
          {children}
        </a>
      </NextLink>
    )
  }

  return (
    <NextLink href={_urlWithUtms} >
      <a 
        onClick={onClick}
        target={target}
        data-testid={dataTestId}
      >
        {children}
      </a>
    </NextLink>
  )
}