import { useRef } from "react"

import { MobileModal, MobileModalRef } from "components/shared/MobileModal"
import { MobileTextField } from "components/AuthModal/components/MobileTextField"

import { AuthFormTerms } from "../../components/AuthFormTerms"

import { useSocialRegisterViewModel } from "../viewModel"

import styles from "./styles.module.scss"
import { PrimaryButton } from "components/AuthModal/components/PrimaryButton"
import { MobileCancelButton } from "components/AuthModal/components/MobileCancelButton"

type SocialRegisterModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
  animated: boolean
}

export function SocialRegisterModalMobile({ goBackOrClose, onClose, animated }: SocialRegisterModalProps) {

  const mobileModalRef = useRef<MobileModalRef>(null)

  const viewModel = useSocialRegisterViewModel({ goBackOrClose, onClose })

  return (
    <MobileModal
      animated={animated}
      onClose={onClose}
      ref={mobileModalRef}
      isAuthModal
    >
      <form 
        onSubmit={viewModel.handleSubmit(viewModel.handleChooseUsername)}
        className={styles.modalForm}
      >
        <p className={styles.modalTitle}>
          {viewModel.t("oauth.completeRegister.title")} 
        </p>
        <MobileTextField 
          id="username"
          type="text"
          placeholder={viewModel.t('oauth.fields.username')}
          textCase="lower"
          error={viewModel.errors.username?.message}
          register={viewModel.usernameInput}
        />
        <div className={styles.buttonContainer}>
          <PrimaryButton
            className={styles.primary}
          >
            {viewModel.t("oauth.actions.signUp")}
          </PrimaryButton>
        </div>
        <AuthFormTerms
          acceptTerms={viewModel.watchTerms}
          setAcceptTerms={value => viewModel.setValueTrigger('acceptTerms', value)}
          error={viewModel.errors.acceptTerms?.message}
          className={styles.terms}
        />
        <MobileCancelButton 
          onCancel={goBackOrClose}
        />
      </form>
    </MobileModal>
  )
}