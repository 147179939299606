import { AuthScreens } from "components/AuthModal"
import { TextField } from "components/AuthModal/components/TextField"

import { emailValidation } from "utils/validationPatterns"

import { useLoginViewModel } from "../viewModel"

import styles from "./styles.module.scss"
import { AuthFormButton } from "components/AuthModal/components/AuthFormButton"
import { AuthFooterRegister } from "components/AuthModal/components/AuthFooterRegister"
import { ModalCancelButton } from "components/AuthModal/ModalCancelButton"

type LoginModalProps = {
  pushNewScreen: (screen: AuthScreens) => void
  onClose: (dismiss: boolean) => void
}

export const LoginModalDesktop = ({ pushNewScreen, onClose }: LoginModalProps) => {
  const { 
    emailInput, passwordInput, errors, handleSubmit, onSubmit, socialMethods,
    navigateToForgotPassword, handleAppleLogin, t,
    handleFacebookLogin, handleGoogleLogin, areSubmittingForm,
    navigateToRegister
  } = useLoginViewModel({ pushNewScreen, onClose })

  function handleClose() {
    onClose(true)
  }

  return (
    <div>
      <div className={styles.background} onClick={handleClose} />
      <div className={styles.modal}>
        <p className={styles.modalTitle}>
          {t("oauth.authentication.title")}
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.modalForm}>
          <TextField 
            id="email"
            type="email"
            placeholder={t("oauth.fields.email")}
            suffixIcon="/assets/oauth/icon_email.svg"
            error={errors.email?.message}
            register={emailInput}
          />
          <TextField 
            id="password"
            type="password"
            placeholder={t("oauth.fields.password")}
            className={styles.modalFormPassword}
            suffixIcon="/assets/oauth/icon_password.svg"
            error={errors.password?.message}
            register={passwordInput}
          />
          <div>
            <p 
              onClick={navigateToForgotPassword}
              className={styles.modalFormForgotPassword}
            >
              {t("oauth.authentication.forgotPassword")}
            </p>
          </div>
          <AuthFormButton 
            loading={areSubmittingForm}
            text={t("oauth.actions.signIn")}
            className={styles.modalFormButton}
          />
          <AuthFooterRegister
            action={t("oauth.authentication.signupAction")}
            message={t("oauth.authentication.signupMessage")}
            onAction={navigateToRegister}
          />
          {
            socialMethods && (
              <>
                <div className={styles.modalFormDivider}>
                  <div />
                  <p>{t("oauth.authentication.or")}</p>
                  <div />
                </div>
                
                <p className={styles.modalSocialTitle}>
                  {t("oauth.authentication.socialSignInTitle")}
                </p>

                <div className={styles.modalSocialMethods}>
                  {socialMethods.google && (
                    <img
                      alt="google login"
                      onClick={handleGoogleLogin}
                      src="/assets/oauth/icon_google_signin.svg"
                    />
                  )}
                  {socialMethods.facebook && (
                    <img
                      alt="facebook login"
                      onClick={handleFacebookLogin}
                      src="/assets/oauth/icon_facebook_signin.svg"
                    />
                  )}
                  {socialMethods.apple && (
                    <img
                      alt="apple login"
                      onClick={handleAppleLogin}
                      src="/assets/oauth/icon_apple_signin.svg"
                    />
                  )}
                </div>
              </>
            )
          }
          <ModalCancelButton 
            onCancel={handleClose}
          />
        </form>
      </div>
    </div>
  )
}