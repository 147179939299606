import { ChangeEvent } from "react";
import { useRouter } from "next/router"

import { useBreakpoints } from "hooks/useScreen";
import { useDelirecTranslation } from "hooks/useDelirecTranslation";

import { PageRoute } from "services/router/routerService";

import { LocalStorage, LocalStorageKeys } from "utils/storage";
import { locales } from "utils/i18nUtils";

import styles from "./styles.module.scss"

type LanguageSelectProps = {
  className?: string
  pathData: PageRoute
}

function LanguageSelect({ className, pathData }: LanguageSelectProps) {

  const { t, i18n } = useDelirecTranslation()
  const router = useRouter()
  const { asPath } = router
  const { isDesktop } = useBreakpoints()
  
  function handleChangeLanguage(event: ChangeEvent<HTMLSelectElement>) {
    const newLocale = event.target.value
    LocalStorage<string>().set(LocalStorageKeys.selected_language, newLocale)

    const pathQueries = asPath.indexOf("?") > -1 ? `?${asPath.split("?")[1]}` : ""

    const targetPath = `/${newLocale}/${pathData.translations[newLocale]}${pathQueries}`

    router.replace({ pathname: targetPath, search: pathQueries }, undefined, { locale: newLocale }) 
  }

  return (
    <div className={[styles.languageSelector, className].join(" ")}>
       <select
          id="language-select"
          value={i18n.language}
          onChange={handleChangeLanguage}
          title={t("home.selectLang")}
        >
          {
            locales.map((lang) => {
              return (
                <option key={lang} value={lang}>
                  {isDesktop ? t(`locales.${lang}`) : lang.toUpperCase()}
                </option>
              )
            })
          }
      </select>
    </div>
  )
}

export { LanguageSelect }