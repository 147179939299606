import { EventActions, EventNames } from "."
import { sendEvent } from "."

type AppDownloadClickParams = {
  [key:string]: any
  source: string
}

function sendDownloadClickEvent(params: AppDownloadClickParams) {
  sendEvent({
    name: EventNames.download_popup,
    action: EventActions.click,
    params: params
  })
}

export { sendDownloadClickEvent }