import { useEffect, useMemo } from "react"
import { useRouter } from "next/router"
import type { AppProps } from 'next/app'
import Head from "next/head"
import { appWithTranslation } from "next-i18next"
import { Provider } from "react-redux"
import { mutate } from "swr"
import store from "store"
import { QueryClientProvider } from "react-query"

import i18nConfigs from "../../next-i18next.config.js"

import { getSocialMethods } from "services/sso/ssoService"

import { AppComponent } from "components/app"

import { eventsInit, onPageViewEvent } from "utils/events"
import { queryClient } from "utils/queryClient"
import { DEFAULT_LOCALE, SUPPORTED_LANGUAGES } from "utils/constants"

import "../styles/globals.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MultiLanguageString } from "types/utils.js"

type PagePropsData = {
  data?: {
    pathData: {
      translations: MultiLanguageString
    }
  }
}

const Page = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  const safeWindow = typeof window !== 'undefined'
  const _props = pageProps as PagePropsData

  useEffect(() => {
    if (safeWindow) {
      eventsInit()
      logPageView(router.asPath)

      mutate("v1/sso/methods", getSocialMethods)

      window.addEventListener("resize", changeAppHeight)
      changeAppHeight()
    }
  }, [safeWindow])

  const changeAppHeight = () => {
    const app = document.documentElement.querySelector("#__next")
    if (app instanceof HTMLDivElement) {
      app.style.maxHeight = `${window.innerHeight}px`
    }
  }

  useEffect(() => {
    router.events.on("routeChangeComplete", logPageView)

    return () => {
      router.events.off("routeChangeComplete", logPageView)
    }
  }, [router.events])

  const logPageView = (url: string) => onPageViewEvent(url)

  const pathResolver = (locale: string, translations?: MultiLanguageString) => {
    if (translations) {
      const path = translations[locale]
      return `${process.env.NEXT_PUBLIC_SITE_URL}/${locale}/${path}`
    } else {
      return `${process.env.NEXT_PUBLIC_SITE_URL}/${locale}`
    }
  }

  const canonicalPath = useMemo(() => {
    if (router.asPath) {
      return pathResolver(router.locale!)
    }
    return pathResolver(router.locale!, _props.data?.pathData.translations)
  }, [router.locale, _props.data])

  const altPaths = useMemo(() => {
    return SUPPORTED_LANGUAGES.map(locale => {
      if (router.asPath) {
        return {
          path: pathResolver(locale),
          locale
        }
      }
      return {
        path: pathResolver(locale, _props.data?.pathData.translations),
        locale
      }
    })
  }, [router.locale, _props.data])

  const defaultPath = useMemo(() => {
    if (router.asPath) {
      return pathResolver(DEFAULT_LOCALE)
    }
    return pathResolver(DEFAULT_LOCALE, _props.data?.pathData.translations)
  }, [router.locale, _props.data])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#FFFFFF" />
        <meta name="color-scheme" content="light" />
        <meta httpEquiv="content-language" content={router.locale} />
        <meta name="p:domain_verify" content="e023718e2e49a31456373968b8b897a3"/>
        <link rel="canonical" href={canonicalPath} />
        {
          altPaths.map(({ path, locale }) => {
            return <link 
              key={locale}
              rel="alternate"
              hrefLang={locale}
              href={path}
            />
          })
        }
        <link
          rel="alternate"
          hrefLang="x-default"
          href={defaultPath}
        />
      </Head>
      {
        router.asPath.includes("/oauth")
          ? <Component {...pageProps} />
          : (
            <Provider store={store}>
              <QueryClientProvider client={queryClient} >
                <AppComponent
                  pageProps={pageProps}
                  Component={Component}
                />
              </QueryClientProvider>
            </Provider>
          )
      }
    </>
  )
}

export default appWithTranslation(Page, i18nConfigs)
