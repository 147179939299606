import { auth } from './firebaseClient'
import { fetchSignInMethodsForEmail, onAuthStateChanged, sendPasswordResetEmail, User} from "firebase/auth"
import { InvalidCredentialsException, UserNotFoundException } from "./errors"

async function getLoggedUser() {
    return await new Promise<User>((resolve, reject) => {
        onAuthStateChanged(auth, user => {
            if (user) {
                resolve(user)
            } else {
                reject("No user logged in")
            }
        }, error => {
            reject(error)
        })
    })
}

async function getLoggedUserToken() {
    const user = await getLoggedUser()
    return await user.getIdToken()
}

async function getLoggedUserV2() {
    return await new Promise<User|null>((resolve, reject) => {
        onAuthStateChanged(auth, user => {
            if (user) {
                resolve(user)
            } else {
                resolve(null)
            }
        }, error => {
            reject(error)
        })
    })
}

export async function getLoggedUserId(): Promise<string | null> {
    const user = await getLoggedUserV2()
    if (!user) {
        return user
    }
    return user.uid
}

export async function getLoggedUserTokenV2(): Promise<string | null> {
    const user = await getLoggedUserV2()
    if (!user) {
        return user
    }
    return await user.getIdToken()
}

async function recoverPassword(email: string) {
    try {
        await sendPasswordResetEmail(auth, email)
    } catch (err: any) {
        switch (err.code) {
            case "auth/missing-email":
                throw new InvalidCredentialsException()
            case "auth/user-not-found":
                throw new UserNotFoundException()
            default:
                throw err
        }
    }
}

async function getSignInMethodsForEmail(email: string) {
  return await fetchSignInMethodsForEmail(auth, email)
}

export {
  getLoggedUser,
  getLoggedUserToken,
  recoverPassword,
  getSignInMethodsForEmail
}