import styles from './styles.module.scss'

type AuthFormButtonProps = {
  loading?: boolean
  text: string
  type?: "button" | "submit" | "reset",
  onClick?: () => void
  className?: string
}

function AuthFormButton({ 
  loading, text, type = "submit", onClick, className
}: AuthFormButtonProps) {
  
  return (
    <button
      type={type ?? "submit"}
      onClick={onClick}
      className={`${styles.button} ${loading && styles.loadingButton} ${styles["oauth-primary-button"]} ${className}`}
    >
      {text}
    </button>
  )
}

export { AuthFormButton }