import { AxiosError } from "axios"
import { ApiClient } from "services/apiClient"
import * as Sentry from "@sentry/nextjs"

type SSOCheckData = {
  error: boolean
  exists: boolean
}

export const checkOAuthClient = async (clientId: string) => {
  const response = await ApiClient.get<SSOCheckData>(`v1/sso/client/check?clientId=${clientId}`)
  return response.data.exists
}

type SocialLoginMethods = {
  google: boolean
  facebook: boolean
  apple: boolean
}

export async function getSocialMethods() {
  const response = await ApiClient.get<SocialLoginMethods>("v1/sso/methods")
  return response.data
}

export async function checkUserExists(userId: string) {
  const response = await ApiClient.get<SSOCheckData>(`v1/sso/user/check?userId=${userId}`)
  return response.data.exists
}

type UserData = {
  uid: string;
  name?: string
  photo?: string
  username?: string
  bio?: string
  superChef?: boolean
  country?: string
}

export async function getUserByToken(token: string) {
  try {
    const response = await ApiClient.get<UserData>(`v1/sso/user`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (err: any) {
    Sentry.captureException(err)
    const _error = err as AxiosError

    if (_error.isAxiosError) {
      if (_error.response?.status === 400) {
        return undefined
      }
    } else {
      throw err
    }
  }
}

export async function setUserCountry(token: string, country: string, { signal }: AbortController) {
  const response = await ApiClient.post(`v1/sso/user/country`, { country }, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    signal
  })
  return response.data
}

type SSOAuthorizationCode = {
  error: boolean
  code: string
}

export async function generateAuthorizationCode(userId: string, oAuthClientId: string, returnUrl: string) {
  const response = await ApiClient.post<SSOAuthorizationCode>(`v1/sso/user/authorize`, { userId, oAuthClientId, returnUrl })
  return response.data
}

export async function checkUsernameExists(username: string) {
  const response = await ApiClient.get<SSOCheckData>(`v1/sso/username/check?username=${username}`)
  return response.data.exists
}

type SSOSignUpResponse = {
  error: boolean
  message: string
  data?: UserData
}

export async function completeSignUp(
  token: string, name: string, username: string, signInMethods: string[], country?: string
) {
  const response = await ApiClient.post<SSOSignUpResponse>(
    `v1/sso/user/complete`,
    { name, username, signInMethods, country },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}
