import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import styles from "./styles.module.scss"
import { ButtonHTMLAttributes } from "react"

type MobileCancelButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onCancel: () => void
}

export const MobileCancelButton = ({ onCancel, className, ...props }: MobileCancelButtonProps) => {

  const { t } = useDelirecTranslation()

  return (
    <button
      className={[styles.container, className].join(" ")}
      onClick={onCancel}
      type="button"
      {...props	}
    >
      {t("buttonActions.cancel")}
    </button>
  )
}
