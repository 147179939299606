import { useMemo, useState } from "react"
import { LoginModal } from "./LoginModal";
import { RegisterModal } from "./RegisterModal";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import { SocialRegisterModal } from "./SocialRegisterModal";

export type AuthScreens = "login" | "register" | "forgotPassword" | "complete";

type ScreenData = {
  currentScreen: AuthScreens
  previousScreen?: AuthScreens
  goingBack?: boolean
}

type AuthModalProps = {
  initialScreen: AuthScreens
  onClose: (dismiss: boolean) => void
}

export function AuthModal({ initialScreen = "login", onClose }: AuthModalProps) {

  const [authScreen, setAuthScreen] = useState<ScreenData>({ currentScreen: initialScreen });

  const appearAnimated = useMemo(() => {
    return authScreen.currentScreen === initialScreen && !authScreen.goingBack;
  }, [initialScreen, authScreen])

  function pushNewScreen(screen: AuthScreens) {
    if (screen !== authScreen.currentScreen) {
      setAuthScreen({ currentScreen: screen, previousScreen: authScreen.currentScreen });
    }
  }

  function goBackOrClose() {
    if (authScreen.previousScreen !== undefined) {
      setAuthScreen({ currentScreen: authScreen.previousScreen, goingBack: true })
    } else {
      onClose(true)
    }
  }

  switch (authScreen.currentScreen) {
    case "login":
      return <LoginModal 
        pushNewScreen={pushNewScreen}
        onClose={onClose}
        animated={appearAnimated}
      />
    case "register":
      return <RegisterModal 
        goBackOrClose={goBackOrClose}
        onClose={onClose}
        animated={appearAnimated}
      />
    case "forgotPassword":
      return <ForgotPasswordModal 
        goBackOrClose={goBackOrClose}
        onClose={onClose}
        animated={appearAnimated}
      />
    default:
      return <SocialRegisterModal
        goBackOrClose={goBackOrClose}
        onClose={onClose}
        animated={appearAnimated}
      />
  }
}