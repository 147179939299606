import { InputHTMLAttributes } from 'react'

import { InputError } from 'components/shared/Inputs/InputError'

import styles from './styles.module.scss'
import { UseFormRegisterReturn } from 'react-hook-form'

type MobileTextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  placeholder: string
  error?: string
  textCase?: 'lower' | 'upper'
  register?: UseFormRegisterReturn
}

function MobileTextField({
  placeholder, error, textCase, className, register, ...props
}: MobileTextFieldProps) {

  const caseClass = {
    lower: styles.lowerCase,
    upper: styles.upperCase,
  }

  return (
    <div className={styles['text-field-body']}>
      <div className={`${styles['text-field-container']} ${className}`}>
        <input
          placeholder={placeholder}
          className={`${styles['text-field']} ${textCase && caseClass[textCase]}`}
          {...register}
          {...props}	
        />
      </div>
      { error && <InputError message={error} /> }
    </div>
  )
}

export { MobileTextField }
