import * as Sentry from "@sentry/nextjs"

export class Log {
  public static info(message: string, ...args: any[]) {
    if (process.env.NEXT_PUBLIC_ENV === "local") {
      console.log(`[INFO] ${message}`, ...args)
    }
  }

  public static important(message: string, ...args: any[]) {
    if (process.env.NEXT_PUBLIC_ENV === "local") {
      console.log(`[IMPORTANT] ${message}`, ...args)
    }
  }

  public static error(error: Error, tags?: {[key: string]: string}[]) {
    if (process.env.NEXT_PUBLIC_ENV === "local") {
      console.error(`[ERROR] ${error.message}`, error)
    } else {
      Sentry.captureException(error, scope => {
        if (tags) {
          tags.forEach(tag => {
            scope.setTag(tag.key, tag.value)
          })
        }
        return scope
      })
    }
  }
}