import styles from "./styles.module.scss"

type InputErrorProps = {
  message: string
}

function InputError({ message }: InputErrorProps) {

  return (
    <div className={styles.container}>
      <p className={styles.error}>{message}</p>
    </div>
  )
}

export { InputError }