import { useDispatch, useSelector } from "react-redux"
import { useMemo } from "react"

import { logout } from "services/sso/firebaseSignInService"

import { IState } from "store"
import { clearPendingAction, IAuthUser, onChangeIsLoadingUser, onCloseAuthPopUp, onLogin, onLogout, onOpenAuthPopUp, onUpdateUser } from "store/slices/auth"
import { StorageAuthUser } from "types/authUser"
import { LocalStorage, LocalStorageKeys } from "utils/storage"

type PendingAction = (user?: IAuthUser) => void

export const useAuth = () => {
  const dispatch = useDispatch()

  const { user, authScreen, pendingAction, isLoadingUser } = useSelector((state: IState) => state.auth)
  const loggedFromPreviousAction = pendingAction !== undefined

  const handleOpenLogin = () => {
    dispatch(onOpenAuthPopUp({ screen: "login", pendingAction: undefined }))
  }

  const handleOpenRegister = () => {
    dispatch(onOpenAuthPopUp({ screen: "register", pendingAction: undefined }))
  }

  const handleCloseAuthPopUp = (dismiss: boolean) => {
    dispatch(onCloseAuthPopUp())
    if (pendingAction && dismiss) {
      pendingAction(undefined)
      dispatch(clearPendingAction())
    }
  }

  const handleActionOrOpenLogin = (pendingAction: PendingAction) => {
    if (user) {
      pendingAction(user)
    } else {
      dispatch(onOpenAuthPopUp({ screen: "login", pendingAction }))
    }
  }

  const handleOnLogin = (user: IAuthUser) => {
    dispatch(onLogin(user))

    const storageUser: StorageAuthUser = {
      uid: user.uid,
      username: user.username,
    }

    LocalStorage().set(LocalStorageKeys.auth_user, storageUser)
    if (pendingAction) {
      pendingAction(user)
      dispatch(clearPendingAction())
    }
  }

  const handleUpdateUser = (user: IAuthUser) => {
    dispatch(onUpdateUser(user))
  }

  const handleOnLogout = async () => {
    await logout()
    dispatch(onLogout())
    LocalStorage().remove(LocalStorageKeys.auth_user)
  }

  const handleChangeIsLoadingUser = (hasUser: boolean) => {
    dispatch(onChangeIsLoadingUser(hasUser))
  }

  const userFirstName = useMemo(() => {
    if (user?.name) {
      const name = user.name.split(' ')
      return name[0]
    }
    return undefined
  }, [user?.name])

  return {
    user: user,
    userFirstName: userFirstName,
    authScreen: authScreen,
    loggedFromPreviousAction: loggedFromPreviousAction,
    isLoadingUser: isLoadingUser,
    handleCloseAuthPopUp: handleCloseAuthPopUp,
    handleActionOrOpenLogin: handleActionOrOpenLogin,
    handleOpenLogin: handleOpenLogin,
    handleOpenRegister: handleOpenRegister,
    handleOnLogin: handleOnLogin,
    handleOnLogout: handleOnLogout,
    handleChangeIsLoadingUser: handleChangeIsLoadingUser,
    handleUpdateUser: handleUpdateUser
  }
}