import * as Sentry from "@sentry/nextjs"

export const LocalStorageKeys = {
  auth_user: "auth_user",
  groceries_zip_code: (country: string) => `groceries_zip_code_${country}`,
  selected_language: "selected_language",
  system_language: "system_language",
}

export function LocalStorage<T>() {
  const get = (key: string): T | null => {
    if (typeof window === 'undefined') {
      return null
    }
    const result = localStorage.getItem(key)

    if (result) {
      if (result === "true" || result === "false") {
        return (result === "true") as T
      } else if ((result.startsWith("[") && result.endsWith("]")) || (result.startsWith("{") && result.endsWith("}"))) {
        try {
          return JSON.parse(result) as T
        } catch (err) {
          Sentry.captureException(err)
          return result as T
        }
      } else {
        return JSON.parse(result) as T
      }
    }
    return result as null
  }
  const remove = (key: string) => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key)
    }
  }
  const set = (key: string, value: T) => {
    if (typeof window !== 'undefined') {
      let _value: string | undefined = undefined

      if (typeof value === "undefined") {
        remove(key)
      } else {
        _value = JSON.stringify(value)
      }

      if (_value) {
        localStorage.setItem(key, _value)
      }
    }
  }

  return {
    get,
    set,
    remove
  }
}