import { useRef } from "react"

import { MobileTextField } from "components/AuthModal/components/MobileTextField"
import { MobileModal, MobileModalRef } from "components/shared/MobileModal"

import { ModalCancelButton } from "../../ModalCancelButton"

import { useForgotPasswordViewModel } from "../viewModel"

import styles from "./styles.module.scss"
import { PrimaryButton } from "components/AuthModal/components/PrimaryButton"

type ForgotPasswordModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
  animated: boolean
}

export const ForgotPasswordModalMobile = ({
  goBackOrClose, onClose, animated
}: ForgotPasswordModalProps) => {

  const mobileModalRef = useRef<MobileModalRef>(null)

  const viewModel = useForgotPasswordViewModel({ goBackOrClose })

  return (
    <MobileModal
      animated={animated}
      onClose={onClose}
      ref={mobileModalRef}
      isAuthModal
    >
      <form 
        onSubmit={viewModel.handleSubmit(viewModel.handleRecoverPassword)}
        className={styles.modalForm}
      >
        <p className={styles.modalTitle}>{viewModel.t('oauth.forgotPassword.title')}</p>
        <p className={styles.modalDescription}>
          Insert your e-mail address to reset your password
        </p>
        <MobileTextField 
          id="email"
          type="email"
          placeholder={viewModel.t("oauth.fields.email")}
          className={styles["default-spacing"]}
          error={viewModel.errors.email?.message}
          register={viewModel.emailInput}
        />
        <div className={styles.modalFooter}>
          <PrimaryButton
            loading={viewModel.loading}
            className={styles.modalFormButton}
          >
            {viewModel.t("oauth.actions.send")}
          </PrimaryButton>
          {viewModel.success && (
            <p className={styles.modalRecoverSuccess}>
              {viewModel.t("oauth.forgotPassword.recoverEmailSended")}
            </p>
          )}
          <ModalCancelButton onCancel={viewModel.handleReturn} />
        </div>
      </form>
    </MobileModal>
  )
}