import { UseFormRegisterReturn } from 'react-hook-form'
import { HTMLInputTypeAttribute, CSSProperties} from 'react'

import styles from './styles.module.scss'
import { InputError } from 'components/shared/Inputs/InputError'

type TextFieldProps = {
  id?: string
  type?: HTMLInputTypeAttribute
  register: UseFormRegisterReturn
  style?: CSSProperties
  placeholder: string
  suffixIcon?: string
  onSuffixIconClick?: () => void
  className?: string
  error?: string
  textCase?: 'lower' | 'upper'
}

function TextField({
  id, type, style, placeholder, suffixIcon,
  className, error, onSuffixIconClick, register, textCase
}: TextFieldProps) {

  const caseClass = {
    lower: styles.lowerCase,
    upper: styles.upperCase,
  }

  return (
    <div className={styles['text-field-body']}>
      <div className={`${styles['text-field-container']} ${className}`} style={style}>
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          className={`${styles['text-field']} ${textCase && caseClass[textCase]}`}
          {...register}
        />
        {suffixIcon && <img src={suffixIcon} alt="text field icon" onClick={onSuffixIconClick}/>}
      </div>
      { error && <InputError message={error} /> }
    </div>
  )
}

export { TextField }