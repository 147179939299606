import { Log } from "./log"

export const delay = (time: number = 200) => {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

export const retry = <T>(fn: Promise<T>, times: number = 3, _delay: number = 200): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    fn.then(resolve).catch((err: any) => {
      if (times === 0) {
        reject(err)
      } else {
        delay(_delay).then(() => {
          retry(fn, times - 1, _delay).then(resolve).catch(reject)
        })
      }
    })
  })
}

export interface IRequestController {
  abort: (reason?: any) => void
  signal: RequestAbortSignal
}

export interface RequestAbortSignal extends AbortSignal {}

export class RequestController implements IRequestController {

  private readonly controller: AbortController

  constructor() {
    this.controller = new AbortController()
  }

  abort = (reason?: any) => {
    this.controller.abort(reason)
  }

  get signal() {
    return this.controller.signal
  }
  
}
