import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { ChangeEvent, FormEvent } from "react"
import styles from "./styles.module.scss"

type SearchInputProps = {
  placeholder: string
  value: string
  onChange: (newValue: string) => void
  onSubmit: () => void
  className?: string
}

function SearchInput({ placeholder, value, onChange, onSubmit, className }: SearchInputProps) {

  const { t } = useDelirecTranslation()

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onSubmit()
  }

  return (
    <form className={[styles.container, className].join(" ")} onSubmit={handleSubmit}>
      <input
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      <button type="submit">
        <img src="/assets/search/search_icon.svg" alt={t("header.search")} />
      </button>
    </form>
  )
}

export { SearchInput }