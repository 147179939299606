import { ApiClient } from "services/apiClient";
import { getLoggedUserId, getLoggedUserTokenV2 } from "services/sso/firebaseAuthUtilsService";
import { SessionStorage, SessionStorageKeys } from "utils/sessions";

type GraphqlTokenResponse = {
  error: boolean
  message: string
  data?: string
}

export async function generateGraphqlToken(loggedUserToken: string, locale?: string) {
  const { data: response } = await ApiClient.get<GraphqlTokenResponse>(
    `/v1/graphql/token?locale=${locale}`, 
    {
      headers: {
        Authorization: `Bearer ${loggedUserToken}`
      }
    }
  )

  return response.data
}

export async function generateServerSideGraphqlToken(locale: string) {
  const { data: response } = await ApiClient.get<GraphqlTokenResponse>(
    `/v1/graphql/token/server-side?locale=${locale}`
  )

  return response.data
}

export async function getGraphqlToken(locale?: string) {
  const [userToken, userId] = await Promise.all([
    getLoggedUserTokenV2(),
    getLoggedUserId()
  ])
  const sessionKey = SessionStorageKeys.graphql_session_token + `_${userId}_${locale}`
  const sessionToken = SessionStorage<string>().get(sessionKey)

  if (sessionToken) {
    return sessionToken
  }

  const _token = await generateGraphqlToken(userToken!, locale)
  
  SessionStorage<string>().set(sessionKey, _token!)

  return _token!
}

export async function getServerSideGraphQLToken(locale: string) {
  const _token = await generateServerSideGraphqlToken(locale)

  return _token!
}

type LocaleResponse = {
  city: string
  countryCode: string
  isp: string
  lat: number
  lon: number
  region: string
  regionName: string
}

export async function getUserLocaleApi() {
  const response = await ApiClient.get<LocaleResponse>("https://pro.ip-api.com/json?key=ZfV8XRpiwaU44gq")

  return response.data
}