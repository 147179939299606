import { default as NextImage } from "next/image"

type ImageProps = {
  src: string | null | undefined
  alt?: string
  className?: string
  placeholder: string
  style?: React.CSSProperties
  width?: number
  height?: number
  optimized?: boolean
  lazy?: boolean
}

function Image({ src, alt, className, placeholder, style, width, height, optimized, lazy = true }: ImageProps) {

  if (optimized) {
    return (
      <NextImage
        src={src ?? placeholder}
        alt={alt}
        className={className}
        loading={lazy ? "lazy" : "eager"}
        style={style}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = placeholder
        }}
        width={width}
        height={height}
        loader={() => src ?? placeholder}
      />
    )
  }

  return (
    <img 
      src={src ?? placeholder}
      alt={alt}
      className={className}
      loading={lazy ? "lazy" : "eager"}
      style={style}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = placeholder
      }}
      width={width}
      height={height}
    />
  )
}

export { Image }