import { TextField } from "components/AuthModal/components/TextField"

import { useSocialRegisterViewModel } from "../viewModel"

import styles from "./styles.module.scss"
import { ModalCancelButton } from "../../ModalCancelButton"
import { AuthFormTerms } from "../../components/AuthFormTerms"
import { AuthFormButton } from "../../components/AuthFormButton"

type SocialRegisterModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
}

export function SocialRegisterModalDesktop({ goBackOrClose, onClose }: SocialRegisterModalProps) {

  const { 
    usernameInput, errors, handleSubmit, handleChooseUsername, t,
    handleReturn, loading, setValueTrigger, watchTerms, handleClose
  } = useSocialRegisterViewModel({ goBackOrClose, onClose })

  return (
    <div>
      <div className={styles.background} onClick={handleClose} />
      <div className={styles.modal}>
        <p className={styles.modalTitle}>{t('oauth.completeRegister.title')}</p>
        <form onSubmit={handleSubmit(handleChooseUsername)} className={styles.modalForm}>
          <TextField 
            id="username"
            type="text"
            placeholder={t('oauth.fields.username')}
            textCase="lower"
            suffixIcon="/assets/oauth/icon_username.svg"
            error={errors.username?.message}
            register={usernameInput}
          />
          <AuthFormButton
            text={t('oauth.actions.signUp')}
            loading={loading}
            className={styles.modalFormButton}
          />
          <AuthFormTerms
            acceptTerms={watchTerms}
            setAcceptTerms={value => setValueTrigger("acceptTerms", value)}
            error={errors.acceptTerms?.message}
          />
          <ModalCancelButton onCancel={handleReturn} />
        </form>
      </div>
    </div>
  )
}