
import { Link } from "components/shared/Link"
import styles from "./styles.module.scss"

type HeaderLinkProps = {
  title: string
  link: string
}

const HeaderLink = ({ title, link }: HeaderLinkProps) => (
  <li className={styles.container}>
    <Link
      href={link}
      className={styles.link}
    >
      {title}
    </Link>
  </li>
)

export { HeaderLink }