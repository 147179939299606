import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import styles from "./styles.module.scss"

type ModalCancelButtonProps = {
  onCancel: () => void
}

export const ModalCancelButton = ({ onCancel }: ModalCancelButtonProps) => {

  const { t } = useDelirecTranslation()

  return (
    <div className={styles.container}>
      <img
        className={styles.cancelArrow} 
        src="/assets/arrows/auth_arrow_left.svg" 
        height="15"
      />
      <p
        className={styles.cancelText} 
        onClick={onCancel}
      >
        {t("buttonActions.cancel")}
      </p>
    </div>
  )
}