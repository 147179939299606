import styles from './index.module.scss';

type DotLoadingStyle = "primary" | "secondary"

type DotLoadingProps = {
  style?: DotLoadingStyle
}

export function DotLoading({ style = "primary" }: DotLoadingProps) {

  const dotLoadingStyleClass = {
    primary: styles.dotLoadingPrimary,
    secondary: styles.dotLoadingSecondary
  }

  return (
    <div className={[styles.dotLoading, dotLoadingStyleClass[style]].join(" ")}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}