export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
}

export const slugify = (text: string) => {
  return text.trim().toLowerCase()
  .replace(/[\xC0-\xC5]/g, "A")
  .replace(/[\xC6]/g, "AE")
  .replace(/[\xC7]/g, "C")
  .replace(/[\xC8-\xCB]/g, "E")
  .replace(/[\xCC-\xCF]/g, "I")
  .replace(/[\xD0]/g, "D")
  .replace(/[\xD1]/g, "N")
  .replace(/[\xD2-\xD6\xD8]/g, "O")
  .replace(/[\xD9-\xDC]/g, "U")
  .replace(/[\xDD]/g, "Y")
  .replace(/[\xDE]/g, "P")
  .replace(/[\xE0-\xE5]/g, "a")
  .replace(/[\xE6]/g, "ae")
  .replace(/[\xE7]/g, "c")
  .replace(/[\xE8-\xEB]/g, "e")
  .replace(/[\xEC-\xEF]/g, "i")
  .replace(/[\xF1]/g, "n")
  .replace(/[\xF2-\xF6\xF8]/g, "o")
  .replace(/[\xF9-\xFC]/g, "u")
  .replace(/[\xFE]/g, "p")
  .replace(/[\xFD\xFF]/g, "y")
  .replace(/[^A-Z0-9]+/ig, "-")
  .replace(/-$/, "");
}

export function toTwoDigits(number: number) {
  return number < 10 ? `0${number}` : `${number}`
}
