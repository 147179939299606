import { Image } from "components/shared/Image"

import { loggedUserPhotoPlaceholder } from "utils/placeholders"

import { UserProfileOptions } from "../UserProfileOptions"

import { useHeaderProfileViewModel } from "./viewModel"

import styles from "./styles.module.scss"

type HeaderProfileProps = {
  handleOpenLoginModal: () => void
  handleOpenRegisterModal: () => void
}

export function HeaderProfile({ handleOpenLoginModal, handleOpenRegisterModal }: HeaderProfileProps) {

  const { 
    user, showUserOptions, userFirstName, handleOnLogout,
    handleShowUserOptions, handleNavigateToProfile, t
  } = useHeaderProfileViewModel()

  if (user) {
    return (
      <div className={styles.container}>
        <Image 
          src={user.photo}
          placeholder={loggedUserPhotoPlaceholder}
          alt={user.name}
          className={styles.profilePhoto}
        />
        <div 
          onClick={handleShowUserOptions}
          className={styles.containerSigned}
        >
          <p className={styles.loggedUserPrefix}>
          {t("header.hy")}
          </p>
          <p className={styles.loggedUserName}>
            {userFirstName}
          </p>
          <img 
            className={styles.loggedUserSelectArrow}
            src="/assets/arrows/header_profile_down.svg"
          />

          { showUserOptions && (
            <UserProfileOptions
              handleNavigateToProfile={handleNavigateToProfile} 
              handleLogout={handleOnLogout}
            />
          ) }
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <img 
        className={styles.profilePhoto}
        src={loggedUserPhotoPlaceholder}
        onClick={handleOpenLoginModal}
      />
      <div className={styles.containerSignIn}>
        <p 
          className={[styles.authButton, styles.signInButton].join(" ")}
          onClick={handleOpenLoginModal}
        >
          {t("header.login")}
        </p>
        <p 
          className={[styles.authButton, styles.signUpButton].join(" ")}
          onClick={handleOpenRegisterModal}
        >
          {t("header.register")}
        </p>
      </div>
    </div>
  )
}