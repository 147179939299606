import { useRef } from "react"

import { MobileModal, MobileModalRef } from "components/shared/MobileModal"
import { MobileTextField } from "components/AuthModal/components/MobileTextField"
import { MobileCancelButton } from "components/AuthModal/components/MobileCancelButton"
import { PrimaryButton } from "components/AuthModal/components/PrimaryButton"
import { AuthFormTerms } from "components/AuthModal/components/AuthFormTerms"

import { useRegisterViewModel } from "../viewModel"

import styles from "./styles.module.scss"

type RegisterModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
  animated: boolean
}

export function RegisterModalMobile({ goBackOrClose, onClose, animated }: RegisterModalProps) {

  const mobileModalRef = useRef<MobileModalRef>(null)

  const handleAnimatedClose = (dismiss: boolean = true) => {
    mobileModalRef.current?.animatedClose(dismiss)
  }

  const viewModel = useRegisterViewModel({ goBackOrClose, onClose: handleAnimatedClose })

  return (
    <MobileModal
      animated={animated}
      onClose={onClose}
      ref={mobileModalRef}
      isAuthModal
    >
      <form 
        onSubmit={viewModel.handleSubmit(viewModel.handleRegister)}
        className={styles.modalForm}
      >
        <p className={styles.modalTitle}>
          {viewModel.t("oauth.register.title")}
        </p>
        <MobileTextField 
          id="name"
          type="text"
          placeholder={viewModel.t('oauth.fields.name')}
          error={viewModel.errors.name?.message}
          register={viewModel.nameInput}
        />
        <MobileTextField 
          id="username"
          type="text"
          placeholder={viewModel.t('oauth.fields.username')}
          textCase="lower"
          className={styles.formTopMargin}
          error={viewModel.errors.username?.message}
          register={viewModel.usernameInput}
        />
        <MobileTextField 
          id="email"
          type="email"
          placeholder={viewModel.t('oauth.fields.email')}
          className={styles.formTopMargin}
          error={viewModel.errors.email?.message}
          register={viewModel.emailInput}
        />
        {viewModel.watchEmail?.length > 0 && (
          <MobileTextField 
            id="email-confirm"
            type="email"
            placeholder={viewModel.t('oauth.fields.emailConfirm')}
            className={styles.formTopMargin}
            error={
              viewModel.errors.confirmEmail?.type === "validate" ?
                viewModel.t('oauth.errors.emailDontMatch') :
                undefined
            }
            register={viewModel.emailConfirmInput}
          />
        )}
        <MobileTextField 
          id="password"
          type="password"
          placeholder={viewModel.t('oauth.fields.password')}
          className={styles.formTopMargin}
          error={viewModel.errors.password?.message}
          register={viewModel.passwordInput}
        />
        {viewModel.watchPassword?.length > 0 && (
          <MobileTextField
            id="password-confirm"
            type="password"
            placeholder={viewModel.t('oauth.fields.passwordConfirm')}
            className={styles.formTopMargin}
            error={
              viewModel.errors.confirmPassword?.type === "validate" ?
                viewModel.t('oauth.errors.passwordDontMatch') :
                undefined
            }
            register={viewModel.passwordConfirmInput}
          />
        )}
        <div className={styles.buttonContainer}>
          <PrimaryButton
            className={styles.primary}
          >
            {viewModel.t("oauth.actions.signUp")}
          </PrimaryButton>
        </div>
        <AuthFormTerms
          acceptTerms={viewModel.watchAcceptTerms}
          setAcceptTerms={value => viewModel.setValueTrigger('acceptTerms', value)}
          error={viewModel.errors.acceptTerms?.message}
          className={styles.terms}
        />
        <MobileCancelButton 
          onCancel={goBackOrClose}
        />
      </form>
    </MobileModal>
  )
}
