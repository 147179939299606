import { EventActions, EventNames } from "."
import { sendEvent } from "."

type AuthEventParams = {
  [key:string]: any
  user_uid: string
  user_username: string
}

export function sendOnLoginEvent(params: AuthEventParams) {
  sendEvent({
    name: EventNames.login,
    action: EventActions.click,
    params: params
  })
}

export function sendOnRegisterEvent(params: AuthEventParams) {
  sendEvent({
    name: EventNames.reg,
    action: EventActions.click,
    params: params
  })
}
