import { DesktopHeader } from "./components/DesktopHeader"
import { SearchHeader } from "./components/SearchHeader"
import { PageRoute } from "services/router/routerService"

type HeaderProps = {
  pathData: PageRoute
  navigationRoutes: PageRoute[]
}

function Header(props: HeaderProps) {

  return (
    <header>
      <DesktopHeader {...props} />
      <SearchHeader {...props} />
    </header>
  )
}

export { Header }