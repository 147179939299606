import { useState } from "react"
import * as Sentry from "@sentry/nextjs"

import { useDeliRecForm } from "hooks/useDeliRecForm"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { InvalidEmailException, UserNotFoundException } from "../../../services/sso/errors"
import { recoverPassword } from "services/sso/firebaseAuthUtilsService"
import { emailValidation } from "utils/validationPatterns"

type RegisterViewModelProps = {
  goBackOrClose: () => void
}

type SocialRegisterForm = {
  email: string
}

export const useForgotPasswordViewModel = ({ goBackOrClose }: RegisterViewModelProps) => {

  //Helper hooks
  const { 
    register, setError, formState: { errors }, handleSubmit
  } = useDeliRecForm<SocialRegisterForm>()
  const { t } = useDelirecTranslation()

  //Local state
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  //Fields
  const emailInput = register("email", {
    required: { value: true, message: t('oauth.errors.emptyEmail') },
    pattern: { value: emailValidation, message: t("oauth.errors.invalidEmail") }
  })

  function handleReturn() {
    goBackOrClose()
  }

  async function handleRecoverPassword(fields: SocialRegisterForm) {
    if (loading) {
      return;
    }

    setLoading(true)
    setSuccess(false)

    try {
      await recoverPassword(fields.email)
      
      setSuccess(true)
    } catch(err) {
      if (err instanceof InvalidEmailException) {
        setError("email", { type: "custom", message: t("oauth.errors.invalidEmail") })
      } else if (err instanceof UserNotFoundException) {
        setError("email", { type: "custom", message: t("oauth.errors.userNotFound") })
      } else {
        Sentry.captureException(err)
        setError("email", { type: "custom", message: t('generalError') })
      }
    }
    setLoading(false)
  }

  return {
    t,
    loading,
    success,
    errors,
    handleSubmit,
    handleReturn,
    handleRecoverPassword,
    emailInput
  }
}