import { useEffect, useState } from "react";

type BrowserDetection = {
  isChrome: boolean;
  isFirefox: boolean;
  isSafari: boolean;
  isOpera: boolean;
  isEdge: boolean;
}

export const useBrowser = () => {

  const _hasWindow = typeof window !== undefined
  const [hasWindowInClient, setHasWindowInClient] = useState(false)
  const [browser, setBrowser] = useState<BrowserDetection>(getBrowser())

  function getBrowser(): BrowserDetection {
    if (hasWindowInClient) {
      const userAgent = navigator.userAgent.toLowerCase();

      const isChrome = userAgent.indexOf('chrome') > -1;
      const isFirefox = userAgent.indexOf('firefox') > -1;
      const isSafari = userAgent.indexOf('safari') > -1;
      const isOpera = userAgent.indexOf('opera') > -1;
      const isEdge = userAgent.indexOf('edg') > -1;

      return {
        isChrome: isChrome !== null,
        isFirefox: isFirefox !== null,
        isSafari: isSafari !== null,
        isOpera: isOpera !== null,
        isEdge: isEdge !== null
      }
    }
    
    return {
      isChrome: false,
      isFirefox: false,
      isSafari: false,
      isOpera: false,
      isEdge: false
    }
  }

  useEffect(() => {
    if (_hasWindow && !hasWindowInClient) {
      setHasWindowInClient(true)
    }
    if (hasWindowInClient) {
      setBrowser(getBrowser())
    }
  }, [_hasWindow, hasWindowInClient]);

  return browser
}
