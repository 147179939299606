import { useTranslation } from "next-i18next";
import { TOptions, StringMap } from "i18next"

export type Translation = (key: string, options?: string | TOptions<StringMap> | undefined) => string

export const useDelirecTranslation = (keyPrefix?: string) => {
  const translation = useTranslation("common", { keyPrefix: keyPrefix });

  const t = (key: string, options?: string | TOptions<StringMap> | undefined) => translation.t(key, options)

  return {
    ...translation,
    t: t,
    tList: <T = string>(key: string, options?: string | TOptions<StringMap> | undefined) => {
      if(!options) {
        return translation.t(key, { returnObjects: true }) as T[]
      } else if (typeof options === "string") {
        return translation.t(key, { returnObjects: true }) as T[]
      } else {
        return translation.t(key, { ...options, returnObjects: true }) as T[]
      }
    }
  };
}