import { HtmlHTMLAttributes } from "react"
import { Checkbox } from 'components/shared/Form/Checkbox'
import { InputError } from 'components/shared/Inputs/InputError'
import { useDelirecTranslation } from 'hooks/useDelirecTranslation'

import styles from './styles.module.scss'

type AuthFormTermsProps = HtmlHTMLAttributes<HTMLDivElement> & {
  acceptTerms: boolean
  setAcceptTerms: (acceptTerms: boolean) => void
  error?: string
}

function AuthFormTerms({
  acceptTerms, setAcceptTerms, error, className, ...props
}: AuthFormTermsProps) {

  const { t } = useDelirecTranslation()

  return (
    <div
      className={[styles["terms-text-container"], className].join(' ')}
      {...props}
    >
      <div className={styles['terms-text-form']}>
        <Checkbox 
          checked={acceptTerms}
          onChecked={setAcceptTerms}
        />
        <p className={styles["terms-text"]}>
          {t("oauth.terms.first")} <a href="/terms" target="_blank">{t("oauth.terms.second")}</a> {t("oauth.terms.third")} <a href="/privacy" target="_blank">{t("oauth.terms.fourth")}</a> {t("oauth.terms.fifth")}
        </p>
      </div>
      { error && <InputError message={error} /> }
    </div>
  )
}

export { AuthFormTerms }