import { auth } from './firebaseClient'
import * as Sentry from "@sentry/nextjs"
import { createUserWithEmailAndPassword } from 'firebase/auth'
import {
  EmailAlreadyInUseException,
  InvalidCredentialsException,
  TooManyRequestsException,
  WeakPasswordException
} from 'services/sso/errors'

async function passwordSignUp(email: string, password: string) {
  try {
    const credential = await createUserWithEmailAndPassword(auth, email, password)

    return credential.user
  } catch(error: any) {
    Sentry.captureException(error)
    switch (error.code) {
      case "auth/invalid-email":
          throw new InvalidCredentialsException()
      case "auth/email-already-in-use":
      case "auth/account-exists-with-different-credential":
        throw new EmailAlreadyInUseException()
      case "auth/weak-password":
        throw new WeakPasswordException()
      case "auth/too-many-requests":
          throw new TooManyRequestsException()
      default:
          throw error
    }
  }
}

export { passwordSignUp }