import { BottomNavigation } from "components/BottomNavigation"
import { NewAppDownloadModal } from "components/NewAppDownloadModal"
import { PageRoute } from "services/router/routerService"
import { DeliRecPages } from "utils/routes"

import styles from "./styles.module.scss"

type FixedFooterProps = {
  page?: DeliRecPages
  navigationRoutes: PageRoute[]
  showAppDownloadModal: boolean
}

export function FixedFooter({ page, navigationRoutes, showAppDownloadModal }: FixedFooterProps) {

  return (
    <div className={styles.container}>
      { showAppDownloadModal && <NewAppDownloadModal /> }
      <BottomNavigation page={page} navigationRoutes={navigationRoutes} />
    </div>
  )
}