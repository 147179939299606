import { useBreakpoints } from "hooks/useScreen"
import { AuthScreens } from ".."
import { LoginModalDesktop } from "./LoginModalDesktop"
import { LoginModalMobile } from "./LoginModalMobile"

type LoginModalProps = {
  pushNewScreen: (screen: AuthScreens) => void
  onClose: (dismiss: boolean) => void
  animated: boolean
}

export function LoginModal({ pushNewScreen, onClose, animated }: LoginModalProps) {

  const { isDesktop } = useBreakpoints()

  if (isDesktop) {
    return <LoginModalDesktop 
      pushNewScreen={pushNewScreen}
      onClose={onClose}
    />
  }

  return <LoginModalMobile 
    pushNewScreen={pushNewScreen}
    onClose={onClose}
    animated={animated}
  />
}
