const delirec_base_url = "https://www.delirecstore.com"

export const DELIREC_STORE = {
  from_terms: `${delirec_base_url}?utm_source=site&utm_medium=terms`,
}

export const SHOWCASE_SEE_ALL = "https://www.buscape.com.br/landing-page/buscape-cozinha?og=19449&utm_source=Delirec&utm_medium=parceiros"

export const DESKTOP_IMAGE_SIZE = 800
export const PROFILE_PHOTO_CROP_ASPECT = 1

export const USER_LIST_MODAL_SHIMMERING_LIST_SIZE = 10

export const CATEGORY_INITIAL_PAGE = 0
export const CATEGORY_PER_PAGE = 24

export const REVALIDATE_DEFAULT_MINUTES = 10

export const DEFAULT_LOCALE = "en"
export const SUPPORTED_LANGUAGES = ['en', 'pt', 'es', 'fr', 'it', 'de']

export const PLATFORM_NAME = "web"

export const DEFAULT_COUNTRY = "BR"
export const RECIPE_PUBLISH_DEFAULT_LANGUAGE = "pt"

export const GRAPHQL_VERSION = "3.1.0"

export const PREPARATION_TIME_OPTIONS = {
  minutes: "mins(s)",
  hours: "horas(s)",
  days: "dias(s)",
}

export const HAS_WINDOW = typeof window !== "undefined"

export const DEFAULT_RESULT_CACHE_DURATION = 1000 * 60 * 10 // 10 minutos
