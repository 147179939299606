import { useMemo } from "react";
import { MultiLanguageString } from "types/utils";
import { DEFAULT_LOCALE, SUPPORTED_LANGUAGES } from "./constants";
import { slugify } from "./utils";
import { PageRoute } from "services/router/routerService";

type RouterLocales = { locale?: string, defaultLocale?: string }

export const chefUrl = (username: string, router: RouterLocales) => {
  return `/${router.locale}/${username}`
}

export const supportUrl = `https://contact.delirec.com/support/home`

export type DeliRecPages = "about" | "contact" | "contact-dpo" | "privacy" | "terms" 
            | "wallet-terms" | "search" | "category" | "categories" | "home" | "theme-item"
            | "recipe" | "profile" | "edit-profile" | "404" | "send-recipe" | "my-menu"
            | "how-it-works" | "theme" | "box-menu"

export type PathResolverResult = {
  page: DeliRecPages
  detectedPath: string
  localePath: string
  locale: string
  redirect: boolean
}

//Static site urls. Theses urls are static pages on the site.
export const STATIC_PATHS = {
  about: "about",
  boxMenu: "box-menu",
  contact: "contact",
  contactDpo: "contact-dpo",
  myMenu: "my-menu",
  howWorks: "my-menu/how-it-works",
  privacy: "privacy",
  terms: "terms",
  walletTerms: "wallet-terms",
  search: "search",
  categories: "recipe-categories",
  sendRecipe: "send-recipe",
}

export const PUBLIC_STATIC_PATHS = {
  about: "about",
  boxMenu: "box-menu",
  contact: "contact",
  contactDpo: "contact-dpo",
  privacy: "privacy",
  terms: "terms",
  walletTerms: "wallet-terms",
  search: "search",
  categories: "recipe-categories",
  howWorks: "my-menu/how-it-works"
}

export const getLocale = (locale?: string) => {
  if (locale && SUPPORTED_LANGUAGES.includes(locale)) {
    return locale
  }
  return DEFAULT_LOCALE
}

type I18NData = { 
  locale?: string
}

export const pathResolverCategory = (router: I18NData, paths: {[key:string]: string}): string => {
  const { locale } = router

  if (Object.keys(paths).includes(locale ?? "")) {
    return paths[locale!]
  }

  return paths[DEFAULT_LOCALE]
}

export const recipePath = (
  router: I18NData,
  recipeId: string,
  recipeName: string,
  categoryPaths: MultiLanguageString,
  username: string
) => {
  const { locale } = router

  const categoryPath = pathResolverCategory(router, categoryPaths)

  const path = `/${categoryPath}/${slugify(recipeName)}-${recipeId}/${username}`

  return `/${locale}${path}`
}

export const userPath = (
  router: I18NData,
  userName: string
) => {
  const { locale } = router

  return `/${locale}/${userName}`
}

export const navigationRoutePath = (router: I18NData, page: DeliRecPages, routes: PageRoute[] | undefined) => {
  const locale = router.locale ?? DEFAULT_LOCALE
  const pagePath = routes?.find(route => route.page === page)

  if (!pagePath) {
    return `/${locale}`
  }

  if (pagePath.level === 1) {
    return `/${locale}/${pagePath.translations[locale]}`
  }

  return `/${pagePath.translations[locale]}`
}

export const useNavigationRoutePath = (router: I18NData, page: DeliRecPages, routes: PageRoute[] | undefined) => {
  return useMemo(() => navigationRoutePath(router, page, routes), [router.locale, page, routes])
}

export const redirectServerSide = (destination: string, permanent: boolean, locale?: string) => {
  return {
    redirect: {
      permanent: permanent,
      destination: `/${locale}/${destination}`
    }
  }
}