import { TextField } from "components/AuthModal/components/TextField"

import { ModalCancelButton } from "../../ModalCancelButton"
import { AuthFormButton } from "../../components/AuthFormButton"

import { useForgotPasswordViewModel } from "../viewModel"

import styles from "./styles.module.scss"

type ForgotPasswordModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
}

export function ForgotPasswordModalDesktop({ goBackOrClose, onClose }: ForgotPasswordModalProps) {

  const { 
    emailInput, errors, handleSubmit, handleReturn, 
    loading, handleRecoverPassword, t, success
  } = useForgotPasswordViewModel({ goBackOrClose })

  function handleClose() {
    onClose(true)
  }

  return (
    <div>
      <div className={styles.background} onClick={handleClose} />
      <div className={styles.modal}>
        <p className={styles.modalTitle}>{t('oauth.forgotPassword.title')}</p>
        <form onSubmit={handleSubmit(handleRecoverPassword)} className={styles.modalForm}>
          <TextField 
            id="email"
            type="email"
            placeholder={t("oauth.fields.email")}
            suffixIcon="/assets/oauth/icon_email.svg"
            className={styles["default-spacing"]}
            error={errors.email?.message}
            register={emailInput}
          />
          <AuthFormButton
            text={t('oauth.actions.send')}
            loading={loading}
            className={styles.modalFormButton}
          />
          {success && (
            <p className={styles.modalRecoverSuccess}>
              {t("oauth.forgotPassword.recoverEmailSended")}
            </p>
          )}
          <ModalCancelButton onCancel={handleReturn} />
        </form>
      </div>
    </div>
  )
}