import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuFoodProfile, MenuType } from "repository/myMenuRepository";
import { SessionStorage, SessionStorageKeys } from "utils/sessions";

export type MyMenuData = {
  foodProfiles: MenuFoodProfile[]
  menus: MenuOption 
}

type MenuOption = {
  [key:string]: MenuType[]
}
//State
export interface IMyMenuState {
  myMenu: MyMenuData | undefined
}

const INITIAL_STATE: IMyMenuState = {
  myMenu: SessionStorage<MyMenuData>().get(SessionStorageKeys.my_menu_types) ?? undefined,
};

//Slice
const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    onSetMenuTabsData: (state: IMyMenuState, action: PayloadAction<MyMenuData>) => {
      state.myMenu = action.payload
    }
  }
})

export const { 
  onSetMenuTabsData
} = authSlice.actions
export default authSlice.reducer