import { useRouter } from "next/router"

import { PageRoute } from "services/router/routerService"

import { useNavigationRoutePath, supportUrl } from "utils/routes"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"

import { HeaderLink } from "../HeaderLink"
import { LanguageSelect } from "../LanguageSelect"

import styles from "./styles.module.scss"

type DesktopHeaderProps = {
  pathData: PageRoute
  navigationRoutes: PageRoute[]
}

const DesktopHeader = ({ pathData, navigationRoutes }: DesktopHeaderProps) => {

  const { t } = useDelirecTranslation()
  const router = useRouter()

  const aboutPath = useNavigationRoutePath(router, "about", navigationRoutes)
  const categoriesPath = useNavigationRoutePath(router, "categories", navigationRoutes)
  const themesPath = useNavigationRoutePath(router, "theme", navigationRoutes)
  const contactPath = useNavigationRoutePath(router, "contact", navigationRoutes)
  const homePath = useNavigationRoutePath(router, "home", navigationRoutes)

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ul className={styles.linkGroup}>
          <HeaderLink
            title={t("header.home")}
            link={homePath}
          />
          <HeaderLink
            title={t("header.about")}
            link={aboutPath}
          />
          <HeaderLink
            title={t("header.categories")}
            link={categoriesPath}
          />
          <HeaderLink
            title={t("header.themes")}
            link={themesPath}
          />
          <HeaderLink
            title={t("header.contact")}
            link={contactPath}
          />
          <HeaderLink
            title={t("help_central")}
            link={supportUrl}
          />
        </ul>
        <div className={styles.rightLinks}>
          <div className={styles.socialNetworks}>
            <a href="https://www.facebook.com/delirec.br">
              <img src="/assets/socialIcons/facebook_icon_white.svg" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/delirec.br">
              <img src="/assets/socialIcons/instagram_icon_white.svg" alt="Instagram" />
            </a>
          </div>
          <LanguageSelect pathData={pathData} />
        </div>
      </div>
    </div>
  )
}

export { DesktopHeader }