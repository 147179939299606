import { MultiLanguageString } from "../types/utils";
import i18nConfigs from "../../next-i18next.config"
import { getLocale } from "./routes";

type i18n = {
  language: string
}

export const i18nText = (i18n: i18n, text?: string|MultiLanguageString): string|undefined => {
  if (text) {
    const _locale = getLocale(i18n.language)
    const isI18nText = typeof text === "string"

    if (isI18nText) {
      return text
    }

    if (text[_locale]) {
      return text[_locale]
    }
    return text.pt
  }
  return undefined
}

export const i18nTextServerSide = (locale?: string, text?: MultiLanguageString) => {
  if (text) {
    const _locale = getLocale(locale)
    if (_locale && text[_locale]) {
      return text[_locale]
    }
    return text.pt
  }
  return undefined
}

export const locales = i18nConfigs.i18n.locales.filter(locale => locale !== i18nConfigs.i18n.defaultLocale)

export const defaultLocale = "en"
