import { useEffect, useState } from "react"

import { useAuth } from "hooks/useAuth"
import { setUserCountry } from "services/sso/ssoService"
import { useRouter } from "next/router"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useLocale } from "hooks/useLocale"
import { Log } from "utils/log"

export const useHeaderProfileViewModel = () => {
  //Global state
  const { user, userFirstName, handleOnLogin, handleOnLogout } = useAuth()
  const { locale } = useLocale()

  //Helper hooks
  const router = useRouter()
  const { t } = useDelirecTranslation()

  //Local State
  const [showUserOptions, setShowUserOptions] = useState(false)

  //Show user options
  useEffect(() => {
    if (showUserOptions) {
      document.addEventListener('click', handleClose);
      return () => document.removeEventListener('click', handleClose);
    }
  }, [showUserOptions]);

  useEffect(() => {
    if (user && !user?.country && locale?.country && user?.token) {
      const controller = new AbortController()

      setUserCountry(user.token, locale.country, controller)
        .then(() => {
          handleOnLogin({
            ...user,
            country: locale.country
          })
        }).catch(Log.error)

      return () => {
        controller.abort()
      }
    }
  }, [user, locale?.country])

  function handleClose() {
    setShowUserOptions(false)
  }

  function handleShowUserOptions() {
    setShowUserOptions(true)
  }

  function handleNavigateToProfile() {
    router.push(`/${user?.username}`)
  }

  return {
    t,
    user,
    userFirstName,
    showUserOptions,
    handleOnLogout,
    handleShowUserOptions,
    handleNavigateToProfile
  }
}