import ReactGA from "react-ga4"
import mixpanel from 'mixpanel-browser';
import { LocalStorage, LocalStorageKeys } from "utils/storage";
import { StorageAuthUser } from "types/authUser";
import { Log } from "utils/log";
import { sendZaplyEvent } from "services/events/eventService";
import assert from "assert";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

type EventSource = "google" | "mixpanel" | "zaply" | "all"

let isReady = false
let queue: { sources: EventSource[], event: Event<object> }[] = []
let previousUrl: string | undefined = undefined

let zaplyEventId: string | undefined = undefined

function eventsInit() {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GA_ID)
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    debug: process.env.NEXT_PUBLIC_ENV == "local"
  }); 
  isReady = true
  if (process.env.NEXT_PUBLIC_ENV === "local") {
    console.log("Events init")
  }

  processQueue()
}

function processQueue() {
  queue.forEach(({sources, event}) => sendEvent({...event}, sources))
  queue = []
}

function onPageViewEvent(page: string) {
  if (previousUrl) {
    const cleanedPath = page.indexOf("?") > -1 ? page.split("?")[0] : page
    const cleanedPreviousPath = previousUrl.indexOf("?") > -1 ? previousUrl.split("?")[0] : previousUrl

    if (cleanedPath === cleanedPreviousPath) {
      previousUrl = page
      console.log("Blocked page view")
      return;
    }
  }

  previousUrl = page

  if (process.env.NEXT_PUBLIC_ENV === "local") {
    console.log("Page view: ", page)
  } else {
    console.log("Page view: ", page)
    ReactGA.send({ hitType: "pageview", page:page })
    mixpanel.track("web_page_view", {
      page: page
    })
  }
}

export interface Event<T> {
  name: EventNames
  action: EventActions
  params: T
}

export enum EventNames {
  recipe_view,
  chef_view,
  chefs,
  categories,
  external_link,
  home_marketplace,
  home_marketplace_buy,
  recipe_marketplace_buy,
  recipe_marketplace_product_imp,
  shopwindow_view,
  recipe_delirecstore,
  link_marketplace,
  profile,
  search,
  print_recipe,
  share_recipe,
  share_social_recipe,
  download_popup,
  reg,
  login,
  favorite,
  thumbs,
  follow,
  subscribe_1,
  subscribe_2,
  subscribe_3,

  groceries_cep_validated,
  groceries_cep_error,
  groceries_supermarket_list,
  groceries_supermarket_error,
  groceries_basket_show,
  recipe_groceries_buy,
  groceries_buy_ingredients,
  recipe_groceries_view,

  SSO_forgot_password_click,
  SSO_enter_click,
  SSO_login_register_click,
  SSO_google_click,
  SSO_facebook_click,
  SSO_appleId_click,
  SSO_remember_password_click,
  SSO_cancel_click,
  SSO_register_register_click,
  SSO_register_return_click,
  SSO_recov_password_send_click,
  SSO_recov_password_return_click,
  SSO_social_login_register_click,
  SSO_social_login_return_click,

  my_menu_event,
  paywall_mm_popUp,
  paywall_mm_download_app,
  paywall_mm_login,
  
  mm_store_download,
  mm_landing_download,
}

export enum EventActions {
  click,
  view,
  see
}

async function sendEvent(
  { name, action, params }: Event<Object>, sources: EventSource[] = ["google", "mixpanel"]
) {
  const eventName = `web_${EventNames[name]}`

  //40 is the event name length limit. https://support.google.com/analytics/answer/9267744?hl=en
  //The idea behind this assert is to throw an error during development of new events
  //if they are more longer than the limit
  assert(eventName.length <= 40)

  if (isReady) {
    const user = LocalStorage<StorageAuthUser>().get(LocalStorageKeys.auth_user)

    if (user) {
      params = {
        ...params,
        user_uid: user.uid,
        user_username: user.username
      }
    }

    if (zaplyEventId && (sources.includes("zaply") || sources.includes("all"))) {
      params = {
        ...params,
        zaplyEventId
      }
    }

    //100 is the character limit of event paramteres content
    //Because of some events that have dynamic parameters content, such as name of the utensils in the recipe page
    //that method cuts the content if it exceeds the GA limit.
    params = Object.keys(params).reduce((result, key) => {
      const param = (params as { [key:string]: any })[key]
      if (param !== undefined && param !== null) {
        const _param = String(param)
        const paramHardLimit = 100

        result[key] = _param.substring(0, Math.min(_param.length, paramHardLimit))
      }

      return result
    }, {} as { [key:string]: string })

    if (process.env.NEXT_PUBLIC_ENV === "local") {
      console.log("Log event: ", eventName, EventActions[action], params)
    } else {
      if (sources.includes("google") || sources.includes("all")) {
        ReactGA.gtag("event", eventName, params)
      }
      if (sources.includes("mixpanel") || sources.includes("all")) {
        mixpanel.track(eventName, params)
      }
      if (sources.includes("zaply") || sources.includes("all")) {
        try {
          const { error, data: eventId } = await sendZaplyEvent(eventName, params)
          if (!error) {
            zaplyEventId = eventId
          }
        } catch(err: unknown) {
          const _err = err as Error
          Log.error(_err)
        }
      }
    }
  } else {
    if (process.env.NEXT_PUBLIC_ENV === "local") {
      console.log("Queue event: ", eventName, EventActions[action], params)
    }
    queue.push({ 
      sources,
      event: { name, action, params }
    })
  }
}

export { 
  eventsInit, onPageViewEvent, sendEvent
}