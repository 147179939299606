
import CookieConsent from "react-cookie-consent";
import { useDelirecTranslation } from "hooks/useDelirecTranslation";
import { Link } from "../Link";

function RequestCookieConsent() {
  const { t } = useDelirecTranslation();

  return (
    <CookieConsent
      buttonText={t("cookieConsent.action")}
      location="bottom"
      contentStyle={{
        marginLeft: "5px",
        flexBasis: "250px",
      }}
      style={{
        background: "#FFFFFF",
        color: "#707070",
        boxShadow: "1px 1.05rem 1.075rem 1.175rem rgb(0 0 0 / 10%)",
        fontFamily: "TIM",
        padding: "10px 40px",
        fontSize: "13px",
      }}
      buttonStyle={{
        background: "#05AFC6",
        color: "#FFFFFF",
        fontSize: "13px",
        padding: "6px 30px 6px 30px",
        borderRadius: "32px",
        marginLeft: "0px",
      }}
    >
      <>
        {t("cookieConsent.message")}{" "}
        <Link href={"/privacy"}>
          {t("privacy")}.
        </Link>
      </>
    </CookieConsent>
  );
}

export { RequestCookieConsent };
