import { useEffect, useState } from "react"
import * as Sentry from "@sentry/nextjs"

import { checkUsernameExists, completeSignUp } from "services/sso/ssoService"
import { getLoggedUser, getLoggedUserToken, getSignInMethodsForEmail } from "services/sso/firebaseAuthUtilsService"

import { useDeliRecForm } from "hooks/useDeliRecForm"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useAuth } from "hooks/useAuth"
import { sendOnRegisterEvent } from "utils/events/authEvents"
import { useLocale } from "hooks/useLocale"


type RegisterViewModelProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
}

type SocialRegisterForm = {
  username: string
  acceptTerms: boolean
}

export const useSocialRegisterViewModel = ({ goBackOrClose, onClose }: RegisterViewModelProps) => {

  //Helper hooks
  const { handleOnLogin, handleOnLogout } = useAuth()
  const { 
    register, setError, formState: { errors }, watch, setValueTrigger, handleSubmit
  } = useDeliRecForm<SocialRegisterForm>()
  const { t } = useDelirecTranslation()
  const { locale } = useLocale()

  //Local state
  const [loading, setLoading] = useState(false)

  //Helper variables
  const watchTerms: boolean | undefined = watch("acceptTerms")

  //Form inputs
  const usernameInput = register("username", {
    required: { value: true, message: t('oauth.errors.emptyUsername') },
    maxLength: { value: 30, message: t('oauth.errors.usernameTooLong') },
    pattern: { value: /^[a-zA-Z0-9_]+$/, message: t('oauth.errors.usernameInvalid') },
  })

  useEffect(() => {
    register("acceptTerms", {
      required: { value: true, message: t("oauth.errors.didNotAcceptedTheTerms") }
    })
  }, [register])

  async function getUser() {
    try {
      return await getLoggedUser()
    } catch (err) {
      Sentry.captureException(err)
      return null;
    }
  }

  async function handleReturn() {
    await handleOnLogout()
    goBackOrClose()
  }

  async function handleClose() {
    await handleOnLogout()
    onClose(true)
  }

  async function handleChooseUsername(fields: SocialRegisterForm) {
    if (loading) {
      return;
    }
    setLoading(true)
    
    //Validate username
    const isUsernameUsed = await checkUsernameExists(fields.username)
      
    if (!isUsernameUsed) {
      //Get logged user
      const credentials = await getUser()

      if (credentials) {
        //Get SignInMethods used for logged user
        const signInMethods = await getSignInMethodsForEmail(credentials.email!)

        const token = await credentials.getIdToken()

        const { error, data: userData } = await completeSignUp(
          token,
          credentials.displayName!,
          fields.username,
          signInMethods,
          locale?.country
        )
        
        if (error) {
          setError("username", { type: "custom", message: t("generalError") })
        } else {
          const token = await getLoggedUserToken()

          sendOnRegisterEvent({
            user_uid: userData!.uid,
            user_username: userData!.username ?? "",
            method: signInMethods[0]
          })
          
          handleOnLogin({
            ...userData!,
            token: token
          })
          onClose(true);
        }
      } else {
        setError("username", { type: "custom", message: t("generalError") })
      }
    } else {
      setError("username", { type: "custom", message: t("oauth.errors.usernameNotAvailable") })
    }
    
    setLoading(false)
  }

  return {
    t,
    loading,
    usernameInput,
    errors,
    watchTerms,
    setValueTrigger,
    handleSubmit,
    handleReturn,
    handleClose,
    handleChooseUsername
  }
}