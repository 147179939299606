import styles from './styles.module.scss'

type AuthFooterRegisterProps = {
  action: string
  message: string
  onAction: () => void
}

function AuthFooterRegister({ action, message, onAction }: AuthFooterRegisterProps) {

    return (
        <div className={styles["oauth-form-footer-container"]}>
          <p>{message}</p>
          <p className={styles["oauth-form-footer-action"]} onClick={onAction}>
            {action}
          </p>
        </div>
    )
}

export { AuthFooterRegister }