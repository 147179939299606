import { forwardRef, useImperativeHandle, useState } from "react";
import styles from "./styles.module.scss";

type MobileModalProps = {
  animated: boolean
  onClose: (dismiss: boolean) => void
  children: string | React.ReactNode | React.ReactNode[]
  isAuthModal?: boolean
}

export type MobileModalRef = {
  animatedClose: (dismiss?: boolean) => void
}

type RequestClose = {
  isDismiss: boolean
}

export const MobileModal = forwardRef<MobileModalRef, MobileModalProps>((
  { onClose, children, animated, isAuthModal },
  ref
) => {
  const [requestClose, setRequestClose] = useState<RequestClose>()

  useImperativeHandle(ref, () => ({
    animatedClose: handleRequestClose
  }))

  const handleOnAnimationEnd = () => {
    if (requestClose) {
      onClose(requestClose.isDismiss)
    }
  }

  const handleRequestClose = (dismiss?: boolean) => {
    setRequestClose({
      isDismiss: dismiss ?? true
    })
  }

  return (
    <div className={styles.container}>
      <div className={[
        isAuthModal ? styles.backgroundAuth : styles.background,
        animated ? styles.backgroundOpenAnimated : "",
        requestClose ? styles.backgroundCloseAnimated : ""
      ].join(" ")} 
        onClick={() => handleRequestClose()}
      />
      <div className={[
          isAuthModal ? styles.modalAuth : styles.modal,
          animated ? styles.modalOpenAnimated : "",
          requestClose ? styles.modalCloseAnimated : ""
        ].join(" ")}
        onAnimationEnd={handleOnAnimationEnd}
      >
        {children}
      </div>
    </div>
  )
})
