import { useRef } from "react";

import { AuthScreens } from "components/AuthModal";
import { MobileModal, MobileModalRef } from "components/shared/MobileModal";
import { MobileTextField } from "components/AuthModal/components/MobileTextField";
import { MobileCancelButton } from "components/AuthModal/components/MobileCancelButton";
import { PrimaryButton } from "components/AuthModal/components/PrimaryButton";

import { useLoginViewModel } from "../viewModel";

import styles from "./styles.module.scss";

type LoginModalProps = {
  pushNewScreen: (screen: AuthScreens) => void
  onClose: (dismiss: boolean) => void
  animated: boolean
}

export const LoginModalMobile = ({ pushNewScreen, onClose, animated }: LoginModalProps) => {

  const mobileModalRef = useRef<MobileModalRef>(null)

  const handleAnimatedClose = (dismiss: boolean = true) => {
    mobileModalRef.current?.animatedClose(dismiss)
  }

  const viewModel = useLoginViewModel({ pushNewScreen, onClose: handleAnimatedClose })

  return (
    <MobileModal
      animated={animated}
      onClose={onClose}
      ref={mobileModalRef}
      isAuthModal
    >
      <form 
        onSubmit={viewModel.handleSubmit(viewModel.onSubmit)}
        className={styles.modalForm}
      >
        <h1 className={styles.modalFormTitle}>
          {viewModel.t("oauth.authentication.title")}
        </h1>
        <MobileTextField 
          type="email"
          placeholder={viewModel.t("oauth.fields.email")}
          error={viewModel.errors.email?.message}
          register={viewModel.emailInput}
          className={styles.emailInput}
        />
        <MobileTextField 
          type="password"
          placeholder={viewModel.t("oauth.fields.password")}
          error={viewModel.errors.password?.message}
          register={viewModel.passwordInput}
        />
        <p 
          onClick={viewModel.navigateToForgotPassword}
          className={styles.modalFormForgotPassword}
        >
          {viewModel.t("oauth.authentication.forgotPassword")}
        </p>
        <div className={styles.modalFormFooter}>
          <PrimaryButton>
            {viewModel.t("oauth.actions.signIn")}
          </PrimaryButton>
          <p className={styles.modalFormRegister}>
            {viewModel.t("oauth.authentication.signupMessage")}{" "}
            <span 
              onClick={viewModel.navigateToRegister}
              className={styles.modalFormRegisterAction}
            >
              {viewModel.t("oauth.authentication.signupAction")}
            </span>
          </p>
          {
            viewModel.socialMethods && (
              <>
                <div className={styles.modalFormSeparator}>
                  <div />
                  <p>
                    {viewModel.t("oauth.authentication.or")}
                  </p>
                  <div />
                </div>
                <p className={styles.modalFormSocialTitle}>
                  {viewModel.t("oauth.authentication.socialSignInTitle")}
                </p>
                <div className={styles.modalFormSocialMethods}>
                  {viewModel.socialMethods.google && (
                    <img
                      onClick={viewModel.handleGoogleLogin}
                      src="/assets/oauth/mobile/ic_google_signin.svg"
                    />
                  )}
                  {viewModel.socialMethods.facebook && (
                    <img
                      onClick={viewModel.handleFacebookLogin}
                      src="/assets/oauth/mobile/ic_facebook_signin.svg"
                    />
                  )}
                  {viewModel.socialMethods.apple && (
                    <img
                      onClick={viewModel.handleAppleLogin}
                      src="/assets/oauth/mobile/ic_apple_signin.svg"
                    />
                  )}
                </div>
              </>
            )
          }
          <MobileCancelButton 
            onCancel={handleAnimatedClose}
          />
        </div>
      </form>
    </MobileModal>
  )
}
