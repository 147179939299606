import { configureStore } from "@reduxjs/toolkit";
import auth, { IAuthState } from "./slices/auth"
import locale, { ILocaleState } from "./slices/locale";
import myMenu, { IMyMenuState } from "./slices/myMenu";

export interface IState {
  auth: IAuthState
  locale: ILocaleState
  myMenu: IMyMenuState
}

export default configureStore({
  reducer: {
    auth,
    locale,
    myMenu
  }
})