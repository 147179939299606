import axios, { AxiosError } from 'axios'
export const apiUrl = `${process.env.NEXT_PUBLIC_API}`

const ApiClient = axios.create({baseURL: apiUrl})

ApiClient.interceptors.request.use(async config => {
  const headers = config.headers ?? {}
  
  headers['Accept'] = 'application/json';
  headers['Content-Type'] = 'application/json';

  config.headers = headers
  return config
}, error => {
  return Promise.reject(error)
})

ApiClient.interceptors.response.use(response => {
  return response;
}, (error: AxiosError) => {
  return Promise.reject(error)
})

export { ApiClient }
