import { useCallback, useState } from "react"
import { useRouter } from "next/router"

import { useAuth } from "hooks/useAuth"
import { useDelirecTranslation } from "hooks/useDelirecTranslation" 

import { PageRoute } from "services/router/routerService"

import { Link } from "components/shared/Link"
import { AuthModal } from "components/AuthModal"
import { SearchInput } from "Partials/Header/components/SearchHeader/SearchInput"

import { navigationRoutePath, useNavigationRoutePath } from "utils/routes"

import { MobileHeaderMenu } from "../MobileHeaderMenu"
import { LanguageSelect } from "../LanguageSelect"

import styles from "./styles.module.scss"
import { HeaderProfile } from "../HeaderProfile"
import { useBreakpoints } from "hooks/useScreen"

type SearchBarProps = {
  pathData: PageRoute
  navigationRoutes: PageRoute[]
}

export function SearchHeader({ pathData, navigationRoutes }: SearchBarProps) {

  //Hooks
  const { t } = useDelirecTranslation()
  const router = useRouter()
  const auth = useAuth()
  const { isMobile } = useBreakpoints()
  const homePath = useNavigationRoutePath(router, "home", navigationRoutes)
  
  const handleOpenDrawer = () => setOpenDrawer(true)
  const handleCloseDrawer = () => setOpenDrawer(false)
  
  //Local state
  const [openDrawer, setOpenDrawer] = useState(false)
  const [term, setTerm] = useState("")

  function handleSubmit() {
    const { filter: selectedFilters } = router.query as { filter?: string }
    const filterQuery = selectedFilters ? `&filter=${encodeURIComponent(selectedFilters)}` : ""
    router.push({
      pathname: navigationRoutePath(router, "search", navigationRoutes),
      search: `?term=${term}${filterQuery}`
    })
    setTerm("")
  }

  function handleOpenSendRecipePage() {
    auth.handleActionOrOpenLogin((user) => {
      if (user) {
        router.push(navigationRoutePath(router, "send-recipe", navigationRoutes))
      }
    })
  }

  const hideButtosHome = useCallback(() => {
    return router.asPath !== '/'
  }, [router.asPath])

  return (
    <div className={styles.container}>
      <MobileHeaderMenu 
        openDrawer={openDrawer}
        handleCloseDrawer={handleCloseDrawer}
        navigationRoutes={navigationRoutes}
      />
      {
        openDrawer && (
          <div
            className={styles.mobileBackdrop}
            onClick={handleCloseDrawer}
          />
        )
      }
      <div className={styles.content}>
        <img
          src="/assets/header/header_menu_closed.svg"
          alt="Abrir menu"
          onClick={handleOpenDrawer}
          className={styles.headerMenuIcon}
        />
        <div className={styles.logoContainer}>
          <Link href={homePath}>
            <img
              src="/assets/delirec/delirec_logo_blue.svg"
              alt="DeliRec logo"
              className={styles.logo}
            />
          </Link>
        </div>
        <LanguageSelect 
          className={[styles.languageSelect, !hideButtosHome() && styles.hideButtons].join(" ")}
          pathData={pathData}
        />
        <SearchInput 
          placeholder={t("search_input")}
          value={term}
          onChange={setTerm}
          onSubmit={handleSubmit}
          className={styles.desktopInput}
        />
        {
          !isMobile && (
            <div className={styles.headerRightContainer}>
              <button
                className={styles.sendRecipe}
                type="button"
                onClick={handleOpenSendRecipePage}
              >
                <img 
                  src="/assets/send_recipe/add_icon.svg"
                  alt={t("buttonActions.sendRecipe")}
                />
                <span>{t("buttonActions.sendRecipe")}</span>
              </button>
              
            </div>
          )
        }
        {
          !isMobile && (
            <HeaderProfile 
              handleOpenLoginModal={auth.handleOpenLogin}
              handleOpenRegisterModal={auth.handleOpenRegister}
            />
          )
        }
      </div>
      { auth.authScreen && (
        <AuthModal 
          onClose={auth.handleCloseAuthPopUp}
          initialScreen={auth.authScreen}
        />
      ) }
      <SearchInput 
        placeholder={t("search_input")}
        value={term}
        onChange={setTerm}
        onSubmit={handleSubmit}
        className={styles.mobileInput}
      />
      <div className={styles.border}></div>
    </div>
  )
}
