import { useBreakpoints } from "hooks/useScreen"

import { RegisterModalDesktop } from "./RegisterModalDesktop"
import { RegisterModalMobile } from "./RegisterModalMobile"

type RegisterModalProps = {
  goBackOrClose: () => void
  onClose: (dismiss: boolean) => void
  animated: boolean
}

export function RegisterModal({ goBackOrClose, onClose, animated }: RegisterModalProps) {

  const { isDesktop } = useBreakpoints()

  if (isDesktop) {
    return <RegisterModalDesktop 
      goBackOrClose={goBackOrClose}
      onClose={onClose}
    />
  }

  return <RegisterModalMobile 
    goBackOrClose={goBackOrClose}
    onClose={onClose}
    animated={animated}
  />

}