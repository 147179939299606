import { useState, useEffect } from 'react';

type UseBreakpoints = {
  isDesktop: boolean,
  isTablet: boolean,
  isMobile: boolean,
  active: "isDesktop" | "isTablet" | "isMobile"
}

function useBreakpoints() {

  const _hasWindow = typeof window !== undefined
  const [hasWindowInClient, setHasWindowInClient] = useState(false)

  function getWindowDimensions(): UseBreakpoints {
    if (hasWindowInClient) {
      const { innerWidth: width } = window;

      const breakpoints: any = {
        isDesktop: width >= 1200,
        isTablet: width < 1200 && width >= 768,
        isMobile: width < 768
      }

      return {
        ...breakpoints,
        active: Object.keys(breakpoints).find(key => breakpoints[key]) ?? 'isDesktop'
      }
    }

    const breakpoints: any = {
      isDesktop: false,
      isTablet: false,
      isMobile: true
    }
   
    return {
      ...breakpoints,
      active: Object.keys(breakpoints).find(key => breakpoints[key]) ?? 'isDesktop'
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (_hasWindow && !hasWindowInClient) {
      setHasWindowInClient(true)
    }
    if (hasWindowInClient) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      
      setWindowDimensions(getWindowDimensions());
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [_hasWindow, hasWindowInClient]);

  return windowDimensions
}

export { useBreakpoints }
